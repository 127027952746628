<template>
    <div ref="dialogView" class="main-view">
        <div @click="close" style="width: 20px;height: 20px;background-color: rgba(0,0,0,0.06);display: flex;align-items: center;justify-content: center;position: absolute;right: 5px;top: 5px;cursor: pointer;">
            <van-icon name="cross" size="16" color="#888888"/>
        </div>
        <div style="font-size: 14px;font-weight: bold;text-align: left;color: #555555;margin-top: 10px;">
            网页版暂不支持该功能，请扫码或点击下载{{gameAppName}}App。
        </div>
        <div style="margin-top: 20px;display: flex;flex-direction: column;align-items: center;">
            <img src="../../assets/app-url-code.png" style="width: 180px;height: 180px;">
            <span style="font-size: 14px;font-weight: bold;color: #555555;">扫码下载{{gameAppName}}</span>
        </div>

        <div style="display: flex;justify-content: center;margin-top: 30px;">
            <div class="app-download-button" @click="download">
                下载{{gameAppName}}
            </div>
        </div>

    </div>
</template>

<script>

import webConfig from "@/api/config";

export default {
    name: 'HomeLogin',
    data() {
        return {
            appDownloadUrl: '',
            gameAppName: '',
        };
    },
    mounted() {
        this.appDownloadUrl = webConfig.appDownloadUrl;
        this.gameAppName = webConfig.gameAppName;
        this.$nextTick(()=> {
            if (window.WheelEvent) {
                this.$refs.dialogView.addEventListener('wheel', function(event) {
                    event.preventDefault();
                }, { passive: false });
            }
        });
    },
    methods: {
        close() {
            this.$parent.resetPage();
        },
        download () {
            window.open(this.appDownloadUrl, '');
        },
    }
};
</script>

<style scoped>

.main-view {
    width: 300px;
    height: 350px;
    background-color: #ffffff;
    border-radius: 12px;
    z-index: 1002;
    position: relative;
    margin: 50px auto 0;
    padding: 20px;
}
.app-download-button {
    width: 180px;
    height: 40px;
    border-radius: 40px;
    background-color: #ee3a3a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    box-sizing: border-box;
    color: #ffffff;
    user-select: none;
    cursor: pointer;
}
@media screen and (max-width: 900px) {
    .main-view {
        width: 80%;
        height: 420px;
        margin-top: 150px;
    }
    .logo-img {
        width: 50px;
        height: 50px;
        margin-top: 20px;
    }
}
</style>