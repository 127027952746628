<template>
    <div class="center">
        <div class="back-arrow" @click="back">
            <van-icon name="arrow-left"  size="21"/>
        </div>

        <div style="width: 100%;height: 350px;">
            <van-swipe>
                <van-swipe-item v-for="(item,index) in images">
                    <van-image @click="viewImage(index)" :src="item" style="width: 100%;height: 350px;" fit="cover"/>
                </van-swipe-item>
            </van-swipe>
        </div>


        <div class="price-view">
            <span style="font-size: 14px;">￥</span>
            <span style="font-weight: bold">{{goodsInfo.price}}</span>
            <div class="price-view-db">
                <span>安全无忧</span>
            </div>

<!--            <div>
                <span>{{goodsInfo.seeNum}}人看过</span>
            </div>-->
        </div>

        <div  @click="open(gameInfo.gameId)" style="display: flex;margin: 10px 0;align-items: center;padding: 15px 20px;border-bottom: 1px solid #efefef;cursor:pointer;background-color: #ffffff;">
            <img :src="gameInfo.gameIcon" style="width: 60px;height: 60px;border-radius: 8px;">
            <div style="height: 50px; display: flex;flex-direction: column;flex: 1;text-align: left;justify-content: space-between;">
                <span style="margin-left: 15px;font-size: 15px;overflow: hidden;font-weight: bold; white-space: nowrap;width: 70%;text-overflow: ellipsis;">
                    {{gameInfo.gameName}}
                </span>
                <div class="tags">
                    <span v-for="tag in gameInfo.tagList">{{tag}}</span>
                </div>
            </div>
        </div>

        <div class="goods-title">
            <div>{{goodsInfo.title}}</div>

            <div style="font-size: 14px;font-weight: normal;margin-top: 15px;">{{goodsInfo.desc}}</div>

            <div style="font-size: 14px;font-weight: normal;margin-top: 15px;">
                <span>{{goodsInfo.serverName}}</span>
            </div>

            <div v-if="!!goodsInfo.vip" style="margin-top: 15px;">
                <span style="color: #e4730e;border: 1px solid rgba(228,115,14,0.15);background-color: rgba(228,115,14,0.08); border-radius: 1px; padding: 1px 4px;font-size: 14px;">
                    <span style="font-size: 10px;">VIP</span>
                    <span style="font-weight: bold;margin-left: 2px;">{{goodsInfo.vip}}</span>
                </span>
            </div>
        </div>


        <!-- 交易须知 -->
        <div class="process-view">
            <div style="text-align: center;font-size: 16px;font-weight: bold;margin-bottom: 10px; color: #ff671c;">
                交易须知
            </div>
            <div>1. 下单后请及时联系客服付款，以免心仪帐号被他人购买</div>
            <div>2. 角色排名等数据会根据时间而变化</div>
            <div>3. 付款成功后请前往E兔玩app-我-我的订单 页面设置帐号的新帐号名和密码，并及时进行手机号码绑定</div>
            <div>4. 交易客服在线时间 09:00--22:00</div>
            <div>5. 为了您的帐号和财产安全，切勿和任何第三方人员私下交易，由于私下交易而导致的财产损失，平台概不负责</div>
            <div>6. 认准平台官方客服，请从app在线客服页面进入官方客服咨询页面</div>
        </div>


        <div class="footer">
           <!-- <div>
                <span @click="contact">联系卖家</span>
            </div>-->
            <div @click="submitOrder">
                <span>我要购买</span>
            </div>
        </div>

        <div class="me-login-view" v-if="showLogin" @click.self="showLogin = false">
            <home-login ></home-login>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';
    import { Swipe, SwipeItem,ImagePreview } from 'vant';
    import {Toast,Dialog} from 'vant';
    import {webTokenRequest, request} from '@/api/request';
    import HomeLogin from "@/components/home/HomeLogin.vue";

    Vue.use(Swipe);
    Vue.use(SwipeItem);
    Vue.use(ImagePreview);

    export default {
        name: "GoodsDetail",
        components: {HomeLogin},
        data() {
            return {
                showLoading: false,
                showLogin: false,
                goodsId: null,
                goodsInfo: {},
                gameInfo: {},
                images: [],
                usernameRegexp: /^[a-zA-Z0-9]{6,12}$/,
                passwordRegexp: /^[a-zA-Z0-9~!@#$%^&*()_+.-=]{6,12}$/,
                realNameRegexp: /^[\u4E00-\u9FA5·]{1,20}$/,
                carIdRegexp: /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][012])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,

                username: '',
                password: '',
                password2: '',
                realName:'',
                carId:'',
                selectedCard: '1',
            }
        },
        mounted() {
            this.goodsId = this.$route.query.id;
            this.init();
        },
        methods: {
            copyContent(value, type = 'input') {
                const input = document.createElement(type);
                input.setAttribute('readonly', 'readonly'); // 设置为只读, 防止在 ios 下拉起键盘
                // input.setAttribute('value', value); // textarea 不能用此方式赋值, 否则无法复制内容
                input.value = value;
                document.body.appendChild(input);
                input.setSelectionRange(0, 9999); // 防止 ios 下没有全选内容而无法复制
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
            },
            contact() {
                this.$router.push("/trade/chat")
            },
            back() {
                this.$router.back();
            },
            init() {
                let options = {
                    url:"/trade/getDetail?goodsId=" + this.goodsId,
                    method: 'get'
                };

                request(options).then(res => {
                    let data = res.data.data;
                    console.log(data);
                    this.goodsInfo = data;
                    this.gameInfo = data.gameInfo;
                    this.images = data.imgList;
                });
            },
            resetPage() {
                this.showLogin = false;
            },
            submitOrder() {
                let isLogin = !!localStorage.getItem('web_token');
                if (!isLogin) {
                    this.showLogin = true;
                    return;
                }

                Dialog.confirm({
                    message: '确认要购买该账号吗？',
                }).then(() => {
                    this.showLoading = true;
                    let options = {
                        url:"/trade/createOrder",
                        data: {
                            goodsId: this.goodsId,
                        }
                    };
                    webTokenRequest(options).then(res => {
                        if (res.data.success) {
                            let orderId = res.data.data.orderId;
                            Dialog.alert({
                                title: '下单成功',
                                message: '您的订单号为: ' + orderId + ', 请前往订单详情页面联系客服付款',
                                messageAlign: 'left',
                            }).then(() => {
                                this.$router.push('/trade/myTrade');
                            });
                        }
                        else {
                            Toast(res.data.error.message);
                        }
                    });
                }).catch(() => {
                });
            },
            viewImage(index) {
                ImagePreview({
                    images: this.images,
                    startPosition: index,
                });
            },
            copyToast(v) {
                this.copyContent(v);
                Toast('复制成功');
            },
            open(gameId) {
                //window.open(url, '');
                let channelId = localStorage.getItem('channelId') || 1;
                this.$router.push('/app/' + channelId + '/' + gameId);
            },
        }
    }
</script>

<style scoped>
    .tags {
        margin-left: 15px;
    }
    .tags > span {
        padding: 3px 5px;
        margin-right: 5px;
        background-color: rgba(241, 88, 88, 0.1);
        color: #f15858;
        font-size: 11px;
    }

    .process-view {
        background-color: #FFFFFF;
        text-align: left;
        padding: 10px 15px;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 18px;
        color: #424754;
    }

    .center {
        display: flex;
        flex-direction: column;
        background-color: #EFEFEF;
        height: 100vh;
        overflow-y: auto;
    }
    .title span {
        line-height: 50px;
        display: inline-block;
        margin: 0 auto;
    }
    .back-arrow{
        position: absolute;
        left: 20px;
        top: 35px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 2px;
        z-index: 1100;
    }
    .price-view{
        background-color: #f26146;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 25px;
        color: #ffffff;
        padding: 10px 20px;
        position: relative;
    }
    .price-view-db{
        background-color: rgba(0, 0, 0, 0.18);
        color: #EEEEEE;
        font-size: 12px;
        padding: 2px 6px;
        border-radius: 2px;
        margin-left: 20px;
    }

    .goods-title{
        background-color: #FFFFFF;
        padding: 15px 15px;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        margin-bottom: 10px;
    }
    .goods-info{
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 5px 0;
        margin-bottom: 10px;
    }

    .goods-info > div:first-child {
        text-align: left;
        font-weight: bold;
        font-size: 16px;
        color: red;
    }
    .goods-info > div {
        display: flex;
        font-size: 13px;
        padding: 6px 15px;
        align-items: flex-start;
    }

    .goods-info > div > span:first-child {
        width: 30%;
        text-align: left;
    }
    .goods-info > div > span:nth-child(2) {
        width: 70%;
        text-align: left;
    }
    .footer{
        height: 50px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    /*.footer > div:first-child {
        background-color: #ffba0d;
    }*/
    .footer > div {
        flex: 1;
        background-color: #ff671c;
        height: 50px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-weight: bold;
        cursor: pointer;
    }
    .dialog-view {
        font-size: 14px;
        padding: 15px 10px;
    }
    .dialog-view .van-cell{
        padding: 5px 16px;
    }
    .dialiog-input-view {
        padding: 0 15px;
    }
    .dialiog-input-view > div {
        margin-top: 10px;
    }
    .tips-view-in{

        text-align: left;
        font-size: 12px !important;
        color: #888888;
        padding: 5px 15px;
        display: flex;
    }
    .tips-view-in > span {
        width: 100% !important;
        margin-left: 5px;
    }
    .process-title {
        background-color: #ff8400;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
    }
    .tips-title{
        color: #ff8400;
        font-weight: bold;
        font-size: 14px;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
    }
    .van-field{
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
    }
    .me-login-view {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
</style>