<template>
    <div class="nav-back" style="height: 80px;background-color: #3e4757;width: 100%;">
        <div class="nav-center">
            <div class="logo-view">
                <img :src="logoUrl" style="width: 50px;">
                <span style="margin-left: 20px;font-weight: bold;color: #efefef;font-style: italic;font-size: 25px;">{{ webName }}</span>
            </div>
            <div class="menu-view">
                <div class="menu" :class="index === 1 && 'menu-active'" @click="toIndexPage">
                    首页
                    <div class="active-line" :class="preIndex > 1 ? 'from-right' : 'from-left'"
                         v-show="index === 1"></div>
                </div>
                <div class="menu" :class="index === 2 && 'menu-active'" @click="toGameListPage">
                    所有游戏
                    <div class="active-line" :class="preIndex > 2 ? 'from-right' : 'from-left'"
                         v-show="index === 2"></div>
                </div>
                <div v-if="false" class="menu" :class="index === 3 && 'menu-active'" @click="toTradePage">
                    帐号交易
                    <div class="active-line" :class="preIndex > 3 ? 'from-right' : 'from-left'"
                         v-show="index === 3"></div>
                </div>
                <div class="menu" :class="index === 4 && 'menu-active'" @click="toKfPage">
                    联系客服
                    <div class="active-line" :class="preIndex > 4 ? 'from-right' : 'from-left'"
                         v-show="index === 4"></div>
                </div>
            </div>
            <div class="search">
                <div class="search-box">
                    <img src="../../assets/search.png" style="width: 25px;height: 25px;" @click="searchGame">
                    <span class="split"></span>
                    <input class="search-input" style="font-size: 13px;" v-model="searchName" type="text" :placeholder="defaultSearchName" @keyup.enter="searchGame">
                    <span class="search-btn" @click="searchGame">搜索</span>
                </div>
            </div>
            <div class="icon-container" @mouseenter="showUserDialog">
                <img src="../../assets/user.png" class="nav-user-icon">
                <div v-if="showUserInfo" ref="userInfoRef">
                    <div v-if="isLogin" class="drop-down">
                        <div style="height: 140px;padding-top: 20px;">
                            <img v-if="isLogin && imgUrl" :src="imgUrl" class="user-icon">
                            <img v-else src="../../assets/default-user.png" class="user-icon">
                            <div style="font-size: 16px;color: #666666;font-weight: bold;margin-top: 10px;">已登录：
                                {{ userName }}
                            </div>
                        </div>
                        <div style="height: 80px;display: flex;align-items: center;justify-content: space-around;width: 100%;
                            background-color: #f1f7ff;border-bottom-right-radius: 5px;border-bottom-left-radius: 5px;user-select: none">
                            <div @click="toMyGamePage" style="display: flex;flex-direction: column;align-items: center;cursor: pointer;flex: 1;">
                                <img src="../../assets/game-web.png" style="width: 25px;height: 25px;">
                                <span style="font-size: 13px;font-weight: bold;color: #888888;margin-top: 5px;">我的游戏</span>
                            </div>
                            <div @click="logout" style="display: flex;flex-direction: column;align-items: center;cursor: pointer;flex: 1;">
                                <img src="../../assets/logout-web.png" style="width: 23px;height: 23px;">
                                <span style="font-size: 13px;font-weight: bold;color: #888888;margin-top: 5px;">退出</span>
                            </div>
                        </div>
                    </div>

                    <div v-else class="drop-down">
                        <img src="../../assets/default-user.png" class="user-icon">
                        <div style="font-size: 16px;color: #888888;font-weight: bold;margin-top: 20px;">您还未登录
                            <span @click="openLoginPage"
                                  style="color: #f16868;margin-left: 15px;cursor: pointer;">立即登录</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="showMask" @click="resetPage" class="mask"></div>
        <home-login v-if="showLogin"></home-login>
        <home-app-code v-if="showAppCode"></home-app-code>

    </div>
</template>


<script>

import HomeLogin from "@/components/home/HomeLogin.vue";
import HomeAppCode from "@/components/home/HomeAppCode.vue";

import webConfig from "@/api/config";
import {Dialog, Toast} from "vant";
import {request} from "@/api/request";

export default {
    components: {
        HomeLogin,
        HomeAppCode
    },
    name: "HomeNav",
    data() {
        return {
            index: 0,
            preIndex: '',
            searchName: '',
            userName: '',
            imgUrl: '',
            gameList: [],
            showMask: false,
            showLogin: false,
            showAppCode: false,

            logoUrl: '',
            webName: '',
            defaultSearchName: '',
            isLogin: false,
            showUserInfo: false,
            from: '',
        }
    },
    mounted() {
        this.isLogin = !!localStorage.getItem('web_token');
        this.initActiveMenuIndex();
        this.preIndex = localStorage.getItem('preIndexMenu')
        this.initPage();
    },
    methods: {
        initPage() {
            let config = webConfig;
            this.webName = config.appName;
            this.logoUrl = config.logoUrl;
            this.defaultSearchName = config.defaultSearchName;
            if (this.$route.query.gameName) {
                this.searchName = this.$route.query.gameName;
            }
        },
        resetPage() {
            this.showMask = false;
            this.showUserInfo = false
            this.showLogin = false;
            this.showAppCode = false;
        },
        openLoginPage() {
            this.resetPage();
            this.showLogin = true;
            this.showMask = true;
        },
        exit() {
            this.showMask = false;
            this.showUserInfo = false;
            localStorage.removeItem('web_token');
        },
        showUserDialog() {
            this.isLogin = !!localStorage.getItem('web_token');
            this.userName = localStorage.getItem('web_username');
            this.imgUrl = localStorage.getItem('web_headerImg');
            this.showMask = true;
            this.showUserInfo = true;
            this.$nextTick(()=> {
                if (window.WheelEvent) {
                    this.$refs.userInfoRef.addEventListener('wheel', function(event) {
                        event.preventDefault();
                    }, { passive: false });
                }
            });
        },
        initActiveMenuIndex() {
            let path = this.$route.path;
            if (path === '/index') {
                this.index = 1;
            } else if (path === '/home/gameList') {
                this.index = 2;
            }
            /*else if (path === '/home/trade') {
                this.index = 3;
            }*/
            else if (path === '/home/kf') {
                this.index = 4;
            }
        },
        toIndexPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/index');
        },
        toGameListPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/home/gameList');
        },
        toKfPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/home/kf');
        },
        toTradePage() {
            this.resetPage();
            this.showAppCode = true;
            this.showMask = true;
        },
        toMyGamePage() {
            this.$router.push('/home/myGame');
        },
        searchGame() {
            let name = this.searchName || this.defaultSearchName;
           /* if (this.$route.path === '/home/searchGameList') {
                this.$parent.queryGame(this.searchName);
                return;
            }*/
            this.$router.push({path: '/home/searchGameList', query: {gameName: name}});
        },
        handlerParentClick(message) {
            console.log(message)
        },
        logout() {
            Dialog.confirm({
                message: '确认退出登录吗？',
            }).then(() => {
                let options = {
                    url: "/web/logout",
                    data: {}
                };
                request(options).then(res => {
                    Toast('已成功退出登录');
                    localStorage.removeItem('web_token');
                    localStorage.removeItem('web_user');
                    localStorage.removeItem('web_username');
                    localStorage.removeItem('web_headerImg');
                    this.resetPage();
                });
            }).catch(() => {
            });
        },
    }
}
</script>

<style scoped>


.mask {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    position: absolute;
}

.nav-center {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.logo-view {
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #FFFFFF;
}
.user-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.menu-view {
    display: flex;
    margin-left: 30px;
}

.menu {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    user-select: none;
    position: relative;
}

.menu-active {
    color: #f16868;
}

.active-line {
    width: calc(100% - 40px);
    height: 3px;
    background-color: #f16868;
    position: absolute;
    left: 20px;
    bottom: 0;

}

.from-right {
    animation: right_to_left .3s linear;
}

.from-left {
    animation: left_to_right .3s linear;
}

@keyframes left_to_right {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes right_to_left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@media screen and (max-width: 900px) {
    .nav-center {
        margin-left: 20px;
    }
}

.search {
    margin-left: 50px;
}

.search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 190px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 30px;
    padding-left: 8px;
    padding-right: 2px;
}

.split {
    background-color: #e9ebee;
    height: 20px;
    width: 2px;
    unicode-bidi: isolate;
    flex-shrink: 0;
}

.search-input {
    height: 28px;
    border: none;
    font-size: 14px;
    color: #272b37;
    text-indent: 2px;
    font-family: 'Microsoft YaHei';
    font-weight: normal;
    text-align: left;
    width: 90px;
}

.search-btn {
    background: linear-gradient(90deg, #f57070, #f16868);
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    justify-content: center;
    line-height: 1;
    height: 26px;
    width: 50px;
    flex-shrink: 0;
    font-weight: bold;
    align-items: center;
}

.icons-container {
    margin-left: 20px;
}

.nav-user-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-top: 2px;
}

.icon-container {
    margin-left: 40px;
    position: relative;
    display: inline-block;
}

.icon {
    width: 50px; /* 假设图标宽度为50px */
    height: auto;
    cursor: pointer; /* 鼠标悬停时显示指针 */
}

.tooltip {
    position: absolute;
    bottom: 100%; /* 提示框在图标下方 */
    left: 50%;
    transform: translateX(-50%); /* 居中提示框 */
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    visibility: hidden; /* 初始不可见 */
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
    z-index: 1; /* 确保提示框在上方 */
}

.icon-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.tool span {
    margin-left: 20px
}

.footer-list {
    max-width: 1080px;
    height: 60px;
    margin: auto;
}

.footer-list ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    text-align: center;
}

.footer-list ul i {
    font-size: 20px;
}

.game-item-view {
    width: 430px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 20px 25px;
    box-sizing: border-box;
    overflow: hidden;
}

.game-item-view:hover .game-name-one-line {
    color: #0e7dde;
}

.game-item-view:hover {
    transform: translateY(-3px);
}

.game-name-one-line {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: #333333
}

.game-tag-view {
    display: flex;
    color: #f15858;
    font-size: 12px;
    white-space: nowrap;
}

.game-tag-view span {
    margin-right: 10px;
    background-color: rgba(241, 88, 88, 0.1);
    padding: 2px 4px;
    border-radius: 2px;
}

.drop-down {
    position: absolute;
    top: 60px;
    right: -40px;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 30px 0 10px;
    z-index: 1002;
    width: 300px;
    height: 200px;
    box-sizing: border-box;
}
</style>