<template>
    <div class="center">

        <div class="m-title">
            <div style="width: 100%; display: flex;height: 70px;align-items: center;justify-content: space-between">
                帐号交易

                <div @click="goAddGoodsPage" style="font-size: 16px;height: 70px;">
                    <div style="margin-top: 35px;color: #ff671c;cursor: pointer;">
                        <span style="">
                            我要卖号 <van-icon name="arrow" size="12"/>
                        </span>
                    </div>
                </div>
            </div>


        </div>

        <div class="header">
            <div class="filter-list">
                <div @click="clickTime" style="cursor: pointer;">
                    <span :style="filterTime !== -1 ? 'color: #f56034' : ''">上架时间</span>
                    <van-icon v-if="filterTime === -1" name="play" size="10" color="#999999" style="transition-duration: .5s"></van-icon>
                    <van-icon v-if="filterTime === 0" name="play" size="10" color="#f56034" style="transform:rotate(90deg);transition: transform .5s"></van-icon>
                    <van-icon v-if="filterTime === 1" name="play" size="10" color="#f56034" style="transform:rotate(-90deg);transition: transform .5s"></van-icon>
                </div>
<!--                <div @click="clickHot">
                    <span :style="filterHot !== -1 ? 'color: #f56034' : ''">热度</span>
                    <van-icon v-if="filterHot === -1" name="play" size="10" color="#999999" style="transition-duration: .5s"></van-icon>
                    <van-icon v-if="filterHot === 0" name="play" size="10" color="#f56034" style="transform:rotate(90deg);transition-duration: .5s"></van-icon>
                    <van-icon v-if="filterHot === 1" name="play" size="10" color="#f56034" style="transform:rotate(-90deg);transition-duration: .5s"></van-icon>
                </div>-->
                <div @click="clickPrice" style="cursor: pointer;">
                    <span :style="filterPrice !== -1 ? 'color: #f56034' : ''">价格</span>
                    <van-icon v-if="filterPrice === -1" name="play" size="10" color="#999999" style="transition-duration: .5s"></van-icon>
                    <van-icon v-if="filterPrice === 0" name="play" size="10" color="#f56034" style="transform:rotate(90deg);transition-duration: .5s"></van-icon>
                    <van-icon v-if="filterPrice === 1" name="play" size="10" color="#f56034" style="transform:rotate(-90deg);transition-duration: .5s"></van-icon>
                </div>
                <div @click="showFilterDialog = true" style="cursor: pointer;">
                    <span>筛选</span>
                    <van-icon name="filter-o"  size="10" color="#999999"/>
                </div>
            </div>
        </div>

        <div class="goods-list" id="goodsList">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="pull-view">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad">

                    <div class="goods-item" v-for="item in list" @click="goDetailPage(item.goodsId)">
                        <van-image :src="item.imgList[0]" style="width: 130px;height: 130px;border-radius: 3px;" fit="cover"/>
                        <div class="goods-content">
                            <div class="goods-title">
                                <span class="info-title">{{item.title}}</span>
                            </div>
                            <div>
                                <span>{{item.gameName}}</span>
                            </div>
                            <div style="color: #888888">
                                <span>{{item.serverName}}</span>
                            </div>

                            <div style="color: #f35909;font-size: 12px;">
                                <span>￥</span>
                                <span style="font-size: 18px;font-weight: bold;">{{parseFloat(item.price)}}</span>
                                <span v-if="!!item.vip" style="margin-left: 20px;color: #e4730e;border: 1px solid rgba(228,115,14,0.15);background-color: rgba(228,115,14,0.08); border-radius: 1px; padding: 1px 4px;font-size: 14px;">
                                    <span style="font-size: 10px;">VIP</span>
                                    <span style="font-weight: bold;margin-left: 2px;">{{item.vip}}</span>
                                </span>
                            </div>

                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>

        <van-popup v-model="showGameListDialog" position="bottom" :style="{ width: '100%', height: '60%', borderRadius: '5px;'}">
            <div class="search-game">
<!--                <div style="width: 100%;background-color: #f0f0f0;position: fixed;margin-top: -10px">
                    <div style="display: flex;align-items: center;justify-content: space-between;padding: 10px 10px 10px 30px;">
                        &lt;!&ndash;<van-field type="text" placeholder="请输入游戏名" v-model="gameNameInput" clearable style="width: 200px;"></van-field>&ndash;&gt;
                        <div style="display: flex;justify-content: flex-start;align-items: center;background: #ffffff;padding: 3px 10px;border-radius: 80px;">
                            <van-icon name="search" size="17"/>
                            <span style="width: 2px;height: 20px;background-color: #dbdbdb;margin-left: 10px;"></span>
                            <input v-model="gameNameInput"  type="text" placeholder="输入游戏名" class="search-input">
                        </div>
                        <van-icon name="close" size="30" @click="showGameListDialog = false" color="#888888"/>
                    </div>
                </div>-->

                <div style="width: 100%;height: 50px;font-size: 15px;line-height: 50px;font-weight: bold;position: relative;">
                    选择其他游戏
                    <div @click="showGameListDialog = false" style="width: 20px;height: 20px;background-color: rgba(0,0,0,0.06);display: flex;align-items: center;justify-content: center;position: absolute;right: 10px;top: 15px;cursor: pointer;">
                        <van-icon name="cross" size="16"/>
                    </div>
                </div>

                <div>
                    <div v-for="item in filterGameList" @click="selectGameFromList(item)" style="display: flex;margin: 0 20px;align-items: center;padding: 8px 5px;border-bottom: 1px solid #efefef;cursor:pointer;">
                        <img :src="item.gameIcon" style="width: 35px;height: 35px;border-radius: 4px;">
                        <div style="height: 40px; display: flex;flex-direction: column;flex: 1;text-align: left;justify-content: center;">
                            <span style="margin-left: 15px;font-size: 13px;overflow: hidden;white-space: nowrap;width: 70%;text-overflow: ellipsis;">
                                {{item.gameName}}
                            </span>
                            <div class="tags">
                                <span v-for="tag in item.tagList">{{tag}}</span>
                            </div>
                        </div>
<!--                        <div style="font-size: 12px;color: #888888;">
                            共 <span style="font-size: 12px;font-weight: bold;">{{item.goodsNum || '0'}}</span> 个商品
                        </div>-->
                    </div>
                    <div v-if="filterGameList.length === 0" style="font-size: 14px;color: #888888;margin-top: 80px;">
                        暂无其他游戏
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup v-model="showFilterDialog" position="bottom" :style="{ width: '100%', height: '80%' }" style="border-top-left-radius: 4px;border-top-right-radius: 4px;">
            <div class="filter-view">
                <div style="width: 100%;height: 50px;font-size: 15px;line-height: 50px;font-weight: bold;position: relative;">
                    全部筛选
                    <div @click="showFilterDialog = false" style="width: 20px;height: 20px;background-color: rgba(0,0,0,0.06);display: flex;align-items: center;justify-content: center;position: absolute;right: 10px;top: 15px;cursor: pointer;">
                        <van-icon name="cross" size="16"/>
                    </div>
                </div>
                <div style="padding: 0 15px 15px;height: 100%;overflow-y: auto;box-sizing: border-box">
                    <div style="text-align: left;margin-top: 10px;">
                        <div style="font-size: 14px;display: flex;align-items: center">
                            <div>游戏名称</div>
                        </div>
                        <div class="price-filter-select" style="font-size: 12px;">
                            <div v-for="(item,index) in hotGameList" :class= "{'active':filterGameActive === index}"
                                 @click="selectGame(item, index)" style="width: unset;padding: 0 10px;">{{item.gameName}}</div>
<!--                            <div @click="unSelectedGame" :class= "{'active':filterGameActive === 0}">不限</div>-->
                            <div @click="showGameList" :class= "{'active' : filterGameActive === hotGameList.length}" style="width: unset;padding: 0 10px;">{{selectedOtherGameName}}</div>
                        </div>
                    </div>
                    <div style="text-align: left;margin-top: 20px;">
                        <div style="font-size: 14px;display: flex;align-items: center">
                            <span style="font-size: 14px;width: 80px;">价格区间</span>
                        </div>
                        <div class="price-filter-select">
                            <div v-for="(item,index) in priceList" :class= "{'active':filterPriceActive === index}"
                                 @click="selectPrice(item, index)">{{item}}</div>
                        </div>
                        <div class="input-filter-group" style="margin-top: 10px;width: 260px;">
                            <van-field type="digit" placeholder="自定最低价" style="width: 100px;" v-model="priceStart" clearable></van-field>
                            <div style="padding: 0 10px;">至</div>
                            <van-field type="digit" placeholder="自定最高价" style="width: 100px;" v-model="priceEnd" clearable></van-field>
                        </div>
                    </div>
                    <div style="text-align: left;margin-top: 20px;padding: 1px 0;">
                        <div style="font-size: 14px;width: 80px;">游戏区服</div>
                        <div class="input-filter-group" style="margin-top: 10px;">
                            <van-field  placeholder="请尽量填写准确"  v-model="serverName" clearable style="width: 200px;"></van-field>
                        </div>
                    </div>
                    <div style="text-align: left;margin-top: 20px;padding: 1px 0;">
                        <div style="font-size: 14px;width: 80px;">VIP等级</div>
                        <div class="price-filter-select">
                            <div v-for="(item,index) in vipList" :class= "{'active':filterVipActive === index}"
                                 @click="selectVip(item, index)">{{item}}</div>
                        </div>
                    </div>


<!--                    <div style="text-align: left;margin-top: 20px;padding: 1px 0;">
                        <div style="font-size: 14px;">商品类型：</div>
                        <div class="price-filter-select" style="justify-content: flex-start">
                            <div :class="{'active':goodsType === -1}" @click="goodsType = -1">不限</div>
                            <div :class="{'active':goodsType === 1}" @click="goodsType = 1">游戏帐号</div>
                            <div :class="{'active':goodsType === 2}" @click="goodsType = 2">游戏材料</div>
                        </div>
                    </div>-->
                </div>

                <div class="filter-view-footer">
                    <div @click="reset" class="reset-button">清空选择</div>
                    <div @click="doQueryFilter" class="confirm-button">确定</div>
                </div>
            </div>
        </van-popup>

        <home-tabbar ref="homeTabbarRef"></home-tabbar>

        <div class="me-login-view" v-if="showLogin" @click.self="showLogin = false">
            <home-login ></home-login>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';
    import { Popover,Toast,List,PullRefresh } from 'vant';
    import {request} from '@/api/request';
    import crypto from '@/utils/crypto.js';
    import homeTabbar from "@/components/home/HomeTabbar.vue";
    import HomeLogin from "@/components/home/HomeLogin.vue";

    Vue.use(List);
    Vue.use(Popover);
    Vue.use(PullRefresh);

    export default {
        name: "Goods-List",
        components: {HomeLogin, homeTabbar},
        data() {
            return {
                showLoading: false,
                showFilterDialog: false,
                showGameListDialog: false,
                showLogin: false,
                isLogin: false,
                filterHot: -1,
                filterPrice: -1,
                filterTime: 0,
                actionIndex: -1,
                loading: false,
                finished: false,
                refreshing: false,
                list: [],
                priceStart: null,
                priceEnd: null,
                serverName: null,
                startVip: null,
                endVip: null,
                vipList: ['0~3','4~6', '7~10', '11~12', '13~14', '15及以上'],
                priceList: ['0-50','50-100', '100-500', '500-1000', '1000-2000', '2000以上'],
                filterPriceActive: -1,
                filterGameActive: -1,
                filterVipActive: -1,
                selectedOtherGameName: '其他游戏',
                goodsType: -1,
                pageNo: 1,
                pageSize: 10,
                scrollTop: 0,
                active: 'home',
                gameList: [],
                filterGameList: [],
                selectGameId: '',
                gameNameInput: '',
                hotGameList: [
                    {gameId: 2, gameName: '斗罗大陆-灵眸极速服'},
                    {gameId: 1, gameName: '斗罗大陆-灵眸服'},],
            }
        },
        watch: {
            gameNameInput(n, o) {
                if (!!n) {
                    this.filterGameList = [];
                    this.gameList.forEach((i,k) => {
                        if (i.gameName.indexOf(n) > -1) {
                            this.filterGameList.push(i);
                        }
                    })
                }
                else {
                    this.filterGameList = this.gameList;
                }

            }
        },
        beforeRouteLeave (to, from, next) {
            const $wrapper = document.querySelector('.goods-list');
            this.scrollTop = $wrapper ? $wrapper.scrollTop : 0;
            next();
        },
        activated() {
            this.active = 'home'
            const $wrapper = document.querySelector('.goods-list');
            if (this.scrollTop) {
                $wrapper.scrollTop = this.scrollTop;
            }
            this.$refs.homeTabbarRef.active = 2;
        },
        methods: {
            resetPage() {
                this.showLogin = false;
                this.goAddGoodsPage();
            },
            unSelectedGame() {
                this.selectGameId = '';
                this.filterGameActive = -1;
            },
            showGameList() {
                if (this.gameList.length === 0) {
                    this.getGameList();
                }
                this.showGameListDialog = true;
            },
            getGameList() {
                let options = {
                    url:"/trade/getGameList",
                    method: 'get'
                };

                request(options).then(res => {
                   if (res.data.success) {
                       this.gameList = res.data.data;
                       this.gameList.forEach(e => {
                           let hasHotGame = false;
                           this.hotGameList.forEach(f => {
                               if (e.gameId === f.gameId) {
                                   hasHotGame = true;
                               }
                           });
                           if (!hasHotGame) {
                               this.filterGameList.push(e);
                           }
                       });
                   }
                });
            },
            onLoad() {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                if (!this.priceStart) {
                    this.priceStart = null;
                }
                if (!this.priceEnd) {
                    this.priceEnd = null;
                }
                if (!this.serverName) {
                    this.serverName = null;
                }
                if (!this.selectGameId) {
                    this.selectGameId = null;
                }

                let options = {
                    url:"/trade/getList",
                    data: {
                        pageNo: this.pageNo,
                        pageSize: this.pageSize,
                        params: {
                            goodsType: this.goodsType === -1 ? null : this.goodsType,
                            seeSort:  this.filterHot,
                            priceSort: this.filterPrice,
                            timeSort: this.filterTime,
                            startPrice: this.priceStart,
                            endPrice: this.priceEnd,
                            serverName: this.serverName,
                            startVip: this.startVip,
                            endVip: this.endVip,
                            gameId: this.selectGameId
                        }
                    }
                };

                request(options).then(res => {
                    this.showLoading = false;
                    let data = res.data.list;
                    for (let i = 0; i < data.length; i++) {
                        this.list.push(data[i]);
                    }
                    this.pageNo++;
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    if (!res.data.hasNextPage) {
                        this.finished = true;
                    }

                });
            },
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.loading = true;
                this.pageNo = 1;
                this.onLoad();
            },
            selectGame(item, index) {
                this.filterGameActive = index;
                this.selectGameId = item.gameId;
                this.selectedOtherGameName = '其他游戏';
            },
            selectGameFromList(item) {
                this.filterGameActive = this.hotGameList.length;
                this.selectedOtherGameName = item.gameName;
                this.selectGameId = item.gameId;
                this.showGameListDialog = false;
            },
            selectPrice(item, index) {
                this.filterPriceActive = index;
                if (index === 5) {
                    this.priceStart = 2000;
                    this.priceEnd = 999999;
                }
                else {
                    this.priceStart = item.split('-')[0];
                    this.priceEnd = item.split('-')[1];
                }
            },
            selectVip(item, index) {
                this.filterVipActive = index;
                if (index === 0) {
                    this.startVip = 0;
                    this.endVip = 3;
                }
                else if (index === 1){
                    this.startVip = 4;
                    this.endVip = 6;
                }
                else if (index === 2){
                    this.startVip = 7;
                    this.endVip = 10;
                }
                else if (index === 3){
                    this.startVip = 11;
                    this.endVip = 12;
                }
                else if (index === 4){
                    this.startVip = 13;
                    this.endVip = 14;
                }
                else if (index === 5){
                    this.startVip = 15;
                    this.endVip = 999;
                }
            },
            reset() {
                this.priceStart = null;
                this.priceEnd = null;
                this.filterVipActive = -1;
                this.filterPriceActive = -1;
                this.serverName = null;
                this.goodsType = -1;
                this.startVip = null;
                this.endVip = null;
                this.filterGameActive = -1;
                this.selectedOtherGameName = '其他游戏';
                this.selectGameId = '';
            },
            doQueryFilter() {
                this.showFilterDialog = false;
                this.onRefresh();
            },
            resetFilter() {
                this.filterHot = -1;
                this.filterPrice = -1;
                this.filterTime = -1;
            },
            clickHot() {
                this.filterPrice = -1;
                this.filterTime = -1;
                this.filterHot ++;
                if (this.filterHot > 1) {
                    this.filterHot = 0;
                }
                this.onRefresh();
            },
            clickPrice() {
                this.filterHot = -1;
                this.filterTime = -1;
                this.filterPrice ++;
                if (this.filterPrice > 1) {
                    this.filterPrice = 0;
                }
                this.onRefresh();
            },
            clickTime() {
                this.filterHot = -1;
                this.filterPrice = -1;
                this.filterTime ++;
                if (this.filterTime > 1) {
                    this.filterTime = 0;
                }
                this.onRefresh();
            },
            goDetailPage(id) {
                this.$router.push("/trade/detail?id=" + id)
            },
            goAddGoodsPage() {
                this.isLogin = !!localStorage.getItem('web_token');
                if (!this.isLogin) {
                    this.showLogin = true;
                }
                else {
                    this.$router.push("/trade/goodsAdd")
                }
            },
        }
    }
</script>

<style scoped>
    .m-title {
        display: flex;
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        position: fixed;
        align-items: flex-end;
        padding: 0 20px;
        z-index: 999;
        background-color: #f8f7f5;
        box-sizing: border-box;
    }
    .search-input {
        background: transparent;
        outline: none;
        border: none;
        height: 30px;
        margin-left: 10px;
        width: 150px;
        font-size: 14px;
    }
    .search-game{
        width: 100%;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
    }
    /deep/.search-game .van-cell {
        padding: 5px 16px;
    }
    /deep/.search-game .van-field {
        background-color: rgba(53, 92, 227, 0.05);
        outline: 1px solid #e7e7e7;
    }
    .tags {
        margin-left: 15px;
    }
    .tags > span {
        padding: 3px 5px;
        margin-right: 5px;
        background-color: #f5f5f5;
        color: #a1a1a1;
        font-size: 11px;
    }

    .center {
        display: flex;
        flex-direction: column;
    }
    .header{
        width: 100%;
        background-color: #FFFFFF;
        margin-top: 110px;
    }
    .title{
        height: 50px;
        background-color: #f14f4f;
        color: #FFFFFF;
        font-size: 18px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4000;
    }
    .title span {
        line-height: 50px;
    }
    .filter-list{
        display: flex;

    }
    .filter-list div {
        height: 40px;
        display: flex;
        align-items: center;
        margin-left: 15px;
        font-size: 15px;
    }
    .filter-list > div > i {
        margin-left: 5px;
    }
    .goods-list{
        padding: 0 10px;
        overflow-y: auto;
        height: calc(100vh - 150px);
    }
    .pull-view{
        min-height: calc(100vh - 150px);
    }
    .goods-item{
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        font-size: 14px;
    }
    /deep/.goods-item img{
        border-radius: 5px;
    }
    .goods-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 5px 0 5px 10px;
    }

    .info-title {
        font-size: 16px;
        font-weight: bold;
    }
    .goods-title {
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-align: left;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    }

    .filter-view{
        font-size: 13px;
        overflow: hidden;
        box-sizing: border-box;
    }
    .input-filter-group{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }
    .input-filter-group .van-cell {
        padding: 5px 10px;
        font-size: 12px;
        background-color: rgba(0, 0, 0, 0.04);
        outline: none;
        text-align: center !important;
    }

    /deep/input::placeholder {
        color: #626262;
    }


    .price-filter-select {
        display: flex;
        justify-content: flex-start;
        flex-wrap:wrap;
        column-gap: 10px;
        user-select: none;
    }

    .price-filter-select > div {
        background-color: rgba(0, 0, 0, 0.04);
        color: #222222;
        width: 80px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 3px;
        margin-top: 10px;
        font-size: 12px;
        cursor: pointer;
    }
    .filter-view-footer {
        position: absolute;
        bottom: 0;
        height: 70px;
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 16px;
        color: #FFFFFF;
        user-select: none;
        cursor: pointer;
    }
    .reset-button {
        width: 45%;
        height: 45px;
        border-top: 1px solid #EEEEEE;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f8b91b;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .confirm-button {
        width: 45%;
        height: 45px;
        border-top: 1px solid #EEEEEE;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f14f4f;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .me-login-view {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }

    /deep/.van-image > img {
        width: 130px;
        height: 130px;
    }
    .active{
        background-color: rgba(245, 96, 52, 0.13) !important;
        color: #f56034 !important;
    }
    .van-field{
        outline: 1px #dbdbdb solid;
        border-radius: 3px;
    }
</style>