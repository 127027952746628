<template>
    <div class="center">
        <div class="back-arrow" @click="back">
            <van-icon name="arrow-left"  size="21"/>
        </div>
        <div style="width: 100%;height: 350px;">
            <van-swipe>
                <van-swipe-item v-for="(item,index) in images">
                    <van-image @click="viewImage(index)" :src="item" style="width: 100%;height: 350px;" fit="cover"/>
                </van-swipe-item>
            </van-swipe>
        </div>

        <div class="price-view">
            <span style="font-size: 14px;">￥</span>
            <span style="font-weight: bold">{{goodsInfo.price}}</span>
            <div class="price-view-db">
                <span>安全无忧</span>
            </div>
        </div>

        <div  @click="open(gameInfo.gameId)" style="display: flex;margin: 10px 0;align-items: center;padding: 15px 20px;border-bottom: 1px solid #efefef;cursor:pointer;background-color: #ffffff;">
            <img :src="gameInfo.gameIcon" style="width: 60px;height: 60px;border-radius: 8px;">
            <div style="height: 50px; display: flex;flex-direction: column;flex: 1;text-align: left;justify-content: space-between;">
                <span style="margin-left: 15px;font-size: 15px;overflow: hidden;font-weight: bold; white-space: nowrap;width: 70%;text-overflow: ellipsis;">
                    {{gameInfo.gameName}}
                </span>
                <div class="tags">
                    <span v-for="tag in gameInfo.tagList">{{tag}}</span>
                </div>
            </div>
        </div>

        <div class="goods-info">
            <div style="padding: 10px 15px;">
                <div style="font-size: 18px;font-weight: bold;">{{goodsInfo.title}}</div>

                <div style="margin-top: 15px;">{{goodsInfo.desc}}</div>

                <div style="margin-top: 15px;">
                    <span style="width: 100px;display: inline-block;color: #888888">角色区服</span>
                    <span>{{goodsInfo.serverName}}</span>
                </div>

                <div v-if="!!goodsInfo.vip" style="margin-top: 15px;">
                    <span style="width: 100px;display: inline-block;color: #888888">VIP等级</span>
                    <span style="color: #e4730e;border: 1px solid rgba(228,115,14,0.15);background-color: rgba(228,115,14,0.08); border-radius: 1px; padding: 1px 4px;font-size: 14px;">
                    <span style="font-size: 10px;">VIP</span>
                    <span style="font-weight: bold;margin-left: 2px;">{{goodsInfo.vip}}</span>
                </span>
                </div>
                <div style="margin-top: 15px;">
                    <span style="width: 100px;display: inline-block;color: #888888">上架时间</span>
                    <span>{{goodsInfo.addTime}}</span>
                </div>
            </div>

        </div>

        <div class="goods-info">
            <div style="padding: 10px 15px;">
                <div>
                    <span style="width: 100px;display: inline-block;color: #888888">订单号</span>
                    <span>{{orderInfo.orderId}}</span><span @click="copy(orderInfo.orderId)" style="margin-left: 15px;color: #40a0f5;cursor: pointer;">复制</span>
                </div>
                <div style="margin-top: 15px;">
                    <span style="width: 100px;display: inline-block;color: #888888">下单时间</span>
                    <span>{{orderInfo.orderTime}}</span>
                </div>
                <div style="margin-top: 15px;">
                    <span style="width: 100px;display: inline-block;color: #888888">订单状态</span>
                    <span v-if="orderInfo.orderStatus === '1'">待支付</span>
                    <span v-if="orderInfo.orderStatus === '2'">已支付</span>
                    <span v-if="orderInfo.orderStatus === '3'">已完成</span>
                    <span v-if="orderInfo.orderStatus === '4'">已撤销</span>
                </div>

                <div style="margin-top: 15px;font-size: 12px;color: #f56262;">
                    <span v-if="orderInfo.orderStatus === '1'">请点击下方 联系客服 按钮，联系客服付款</span>
                    <span v-if="orderInfo.orderStatus === '2'">请设置购买帐号的新用户名与密码</span>
                    <span v-if="orderInfo.orderStatus === '3'">交易已完成，可用设置的帐号密码登录游戏</span>
                    <span v-if="orderInfo.orderStatus === '4'">交易已取消</span>
                </div>

                <van-button @click="openKfLink" v-if="orderInfo.orderStatus === '1'" type="info" block round native-type="submit"  style="width: 200px;height: 40px;margin: 10px auto 0;font-size: 14px;font-weight: bold;"
                            color="#ff671c">
                    联系客服
                </van-button>
            </div>
        </div>

        <div v-if="orderInfo.orderStatus === '2'" class="goods-info" style="text-align: center">
            <div style="font-weight: bold;font-size: 14px;color: #ff671c;padding: 5px 15px;">
                请设置用户名和密码
            </div>
            <van-field label="新用户名" label-width="70px" maxlength="30" placeholder="请设置新用户名" v-model="account" clearable></van-field>
            <van-field label="登录密码" type="password" maxlength="12" label-width="70px" placeholder="请设置登录密码"  v-model="password" clearable></van-field>
            <van-field label="确认密码" type="password" maxlength="12" label-width="70px" placeholder="请确认登录密码"  v-model="confirmPassword" clearable></van-field>
            <div style="background-color: rgba(243,18,18,0.07);color: #f54242;font-size: 12px;margin: 10px 10px;border-radius: 5px;padding: 10px 8px;text-align: left;font-weight: bold;">
                设置用户名和密码后，请一定要牢记，否则将无法登录已经购买的账号。
            </div>
            <van-button type="info" @click="submitLoginInfo"  round native-type="submit"  style="width: 200px; height: 40px;margin: 10px 0;font-size: 14px;font-weight: bold;"
                        color="#e73d50">
                提交
            </van-button>

        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Swipe, SwipeItem,ImagePreview } from 'vant';
    import {Toast,Dialog} from 'vant';
    import {webTokenRequest} from '@/api/request';
    import notice from "@/api/notice";
    import webConfig from "@/api/config";
    import myloading from "@/components/myloading.vue";
    import crypto from "@/utils/crypto";

    Vue.use(Swipe);
    Vue.use(SwipeItem);
    Vue.use(ImagePreview);

    export default {
        name: "OrderDetail",
        components: {myloading},
        data() {
            return {
                showLoading: false,
                orderId: null,
                orderInfo: {},
                goodsInfo: {},
                gameInfo: {},
                images: [],

                account: '',
                password: '',
                confirmPassword: '',
                accountPattern: /^[a-zA-Z0-9]{6,12}$/,
                passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+]{6,20}$/,
                webConfig: {},
                tradeKfLink: '',
            }
        },
        mounted() {
            this.webConfig = webConfig;
            this.tradeKfLink = webConfig.wxkfList[0].info;
            this.orderId = this.$route.query.id;
            this.init();
        },
        methods: {
            copy(value) {
                notice.copy(value);
                Toast('订单号复制成功');
            },
            back() {
                this.$router.back();
            },
            init() {
                let options = {
                    url:"/trade/getOrderDetail?orderId=" + this.orderId,
                    method: 'get'
                };

                webTokenRequest(options).then(res => {
                    if (res.data.success) {
                        let data = res.data.data;
                        this.orderInfo = data;
                        this.goodsInfo = data.goods;
                        this.gameInfo = data.goods.gameInfo;
                        this.images = data.goods.imgList;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            submitLoginInfo() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }

                this.showLoading = true;
                let options = {
                    url : "/trade/setNewLoginInfo",
                    data : {
                        username: this.account,
                        password: crypto.encrypt(this.password),
                        orderId: this.orderId,
                    }
                };
                webTokenRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast("用户名与密码设置成功，请牢记您设置的用户名与密码");
                        this.init();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });

            },
            openKfLink() {
                notice.openUrl(this.tradeKfLink);
            },
            viewImage(index) {
                ImagePreview({
                    images: this.images,
                    startPosition: index,
                });
            },
            open(gameId) {
                //window.open(url, '');
                let channelId = localStorage.getItem('channelId') || 1;
                this.$router.push('/app/' + channelId + '/' + gameId);
            },
            checkForm() {
                if (!this.account) {
                    return "请输入新用户名";
                }
                if (!this.accountPattern.test(this.account)) {
                    return this.webName + "用户名为6-12位数字字母";
                }
                if (!this.password) {
                    return "请输入登录密码";
                }
                if (!this.passwordPattern.test(this.password)) {
                    return "密码为6-20位数字字母特殊符号";
                }
                if (!this.confirmPassword) {
                    return "请再次输入登录密码";
                }
                if (this.password !== this.confirmPassword) {
                    return "两次密码输入不一致，请重新输入";
                }
                return "ok";
            },
        }
    }
</script>

<style scoped>
.tags {
    margin-left: 15px;
}
.tags > span {
    padding: 3px 5px;
    margin-right: 5px;
    background-color: rgba(241, 88, 88, 0.1);
    color: #f15858;
    font-size: 11px;
}

.process-view {
    background-color: #FFFFFF;
    text-align: left;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 18px;
    color: #424754;
}

.center {
    display: flex;
    flex-direction: column;
    background-color: #EFEFEF;
    height: 100vh;
    overflow-y: auto;
}
.title span {
    line-height: 50px;
    display: inline-block;
    margin: 0 auto;
}
.back-arrow{
    position: absolute;
    left: 20px;
    top: 35px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    z-index: 1100;
}
.price-view{
    background-color: #f26146;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 25px;
    color: #ffffff;
    padding: 10px 20px;
    position: relative;
}
.price-view-db{
    background-color: rgba(0, 0, 0, 0.18);
    color: #EEEEEE;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 2px;
    margin-left: 20px;
}

.goods-title{
    background-color: #FFFFFF;
    padding: 15px 15px;
    font-size: 13px;
    text-align: left;
    margin-bottom: 10px;
}
.goods-info{
    background-color: #FFFFFF;
    padding: 5px 0;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: left;
    border-radius: 5px;
}

.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-kf-button {
    width: 90%;
    background-color: #ff671c;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
}
.footer-submit-button {
    width: 100%;
    background-color: #ff671c;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
    margin-top: 15px;
}
.van-cell{
    font-size: 13px;
}

</style>