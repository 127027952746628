<template>
    <div ref="loginView" class="login-view">

        <div style="height: 60px; display: flex;align-items: flex-end;font-weight: bold;color: #888888;padding: 0 25px;box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2);">
            <div v-if="!showResetPage" @click="pageActive = 1" style="flex: 1;height: 60px;position: relative; cursor: pointer;display: flex;align-items: center">
                <span :style="pageActive === 1 && 'color: #f16868'" style="flex: 1">登录</span>
                <div style="width: 30px;height: 4px;position: absolute;bottom: 0;right: 0;left: 0;margin: auto;" :style="pageActive === 1 && 'background-color: #f16868;'"></div>
            </div>

            <div v-if="!showResetPage" @click="pageActive = 2" style="flex: 1;height: 60px;position: relative; cursor: pointer;display: flex;align-items: center">
                <span :style="pageActive === 2 && 'color: #f16868'" style="flex: 1">注册</span>
                <div style="width: 30px;height: 4px;position: absolute;bottom: 0;right: 0;left: 0;margin: auto;" :style="pageActive === 2 && 'background-color: #f16868;'"></div>
            </div>

            <div v-if="showResetPage" @click="backLoginPage" style="width: 40px;height: 60px;margin-left: -25px; display: flex;align-items: center;justify-content: center;cursor: pointer">
                <van-icon name="arrow-left" color="#333333" size="20"/>
            </div>
            <div v-if="showResetPage" @click="pageActive = 3" style="width: 80px;height: 60px;position: relative; cursor: pointer;display: flex;align-items: center;margin-left: 10px;">
                <span :style="pageActive === 3 && 'color: #f16868'" style="flex: 1">重置密码</span>
                <div style="width: 30px;height: 4px;position: absolute;bottom: 0;right: 0;left: 0;margin: auto;" :style="pageActive === 3 && 'background-color: #f16868;'"></div>
            </div>
        </div>

        <img  :src="logoUrl" class="logo-img">

        <div v-if="pageActive === 1" class="login-form">
            <span class="ds-label" style="margin-top: 2px;font-weight: bold">{{ this.webName }}账号</span>
            <van-field label-width="0" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       :placeholder="`请输入${ this.webName }账号`" v-model="account" clearable>
            </van-field>

            <span class="ds-label" style="margin-top: 20px;font-weight: bold">密码</span>
            <van-field label-width="0" type="password" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       placeholder="请输入登录密码"  v-model="password" clearable>

                <template #button>
                    <span @click="toResetPage" style="color: #888888;font-size: 12px;cursor: pointer;">忘记密码?</span>
                </template>

            </van-field>

            <div style="width: 100%;position: relative;">
                <van-button type="info" @click="submitLogin" round native-type="submit" :loading="loading" style="width: 150px; height: 40px;margin-top: 30px;font-size: 14px;"
                            loading-text="登录中"
                            color="#e73d50">
                    登 录
                </van-button>
            </div>

        </div>

        <div v-if="pageActive === 2" class="login-form">
            <van-field label-width="0" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       :placeholder="`请输入${ this.webName }账号`" v-model="r_account" clearable>
            </van-field>

            <van-field label-width="0" type="password" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       placeholder="请输入密码"  v-model="r_password" clearable/>

            <van-field label-width="0" type="password" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 10px;"
                       placeholder="请再次输入密码"  v-model="r_password1" clearable/>

            <van-checkbox v-model="isRead" icon-size="14px" shape="square" checked-color="#e73d50">
                <p style="font-size: 13px;">我已阅读并同意<span @click="openReadUrl" style="color: #e73d50;">《{{ this.webName }}用户协议》</span></p>
            </van-checkbox>

            <van-button @click="submitRegister" type="info" round native-type="submit" :loading="loading" style="width: 150px; height: 40px;margin-top: 5px;font-size: 14px;"
                        loading-text="注册中" color="#e73d50">
                立即注册
            </van-button>
        </div>

        <div v-if="pageActive === 3" class="login-form">
            <van-field label-width="0" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 0;"
                       :placeholder="`请输入${ this.webName }账号`" v-model="r_account" clearable>
            </van-field>

            <van-field label-width="0" type="password" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 5px;"
                       placeholder="请输入新密码"  v-model="r_password" clearable/>

            <van-field label-width="0" type="password" label="" style="background-color: #eef2fa;border-radius: 8px;margin-top: 5px;"
                       placeholder="请再次输入新密码"  v-model="r_password1" clearable/>

            <van-field label-width="0" label=""  v-model="verifyCode" type="number" style="background-color: #eef2fa;border-radius: 8px;margin-top: 5px;"
                       placeholder="请输入验证码" clearable >
                <template #button>
                    <van-count-down
                        v-show="showCountDown"
                        ref="countDown"
                        :time="60000"
                        :auto-start="false"
                        format="重新发送(ss秒)"
                        style="font-size: 14px;color: #888888"
                        @finish="finish"
                    />
                    <span style="color: #e73d50;font-size: 13px;cursor: pointer" v-show="!showCountDown" @click="sendSmsForReset">发送验证码</span>
                </template>
            </van-field>

            <van-button @click="submitReset"  type="info" round native-type="submit" :loading="loading" style="width: 150px; height: 40px;margin-top: 10px;font-size: 14px;"
                        loading-text="正在提交" color="#e73d50">
                立即重置
            </van-button>
        </div>

        <div v-if="readDialogShow" class="my-read-dialog">
            <iframe v-show="true" class="iframe-box" :src="readUrl" frameborder="0" scrolling="y"
                    width="100%" height="100%" ></iframe>
        </div>

    </div>
</template>

<script>

import {Dialog, Toast} from "vant";
import crypto from "@/utils/crypto";
import {request} from "@/api/request";
import webConfig from "@/api/config";
import notice from "@/api/notice";

export default {
    name: 'HomeLogin',
    data() {
        return {
            account: '',
            password: '',
            pageActive: 1,
            r_account: '',
            r_password: '',
            r_password1: '',
            accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
            passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,20}$/,
            codePattern: /^\d{6}$/,

            logoUrl: '',
            webName: '',
            loading: false,
            isLogin: false,
            showUserInfo: false,
            isRead: false,
            readDialogShow: false,
            readUrl: '',
            verifyCode: '',
            showCountDown: false,
            showResetPage: false,
        };
    },
    mounted() {
        this.initPage();
    },
    methods: {
        initPage() {
            let config = webConfig;
            this.webName = config.appName;
            this.logoUrl = config.logoUrl;
            this.readUrl = config.agreement;

            this.pageActive = 1;
            this.$nextTick(()=> {
                if (window.WheelEvent) {
                    this.$refs.loginView.addEventListener('wheel', function(event) {
                        event.preventDefault();
                    }, { passive: false });
                }
            });
        },
        backLoginPage() {
            this.r_account = '';
            this.r_password = '';
            this.r_password1 = '';
            this.isRead = false;
            this.pageActive = 1;
            this.showResetPage = false;
        },
        toResetPage() {
            this.r_account = '';
            this.r_password = '';
            this.r_password1 = '';
            this.verifyCode = '';
            this.pageActive = 3;
            this.showResetPage = true;
        },
        openReadUrl() {
            notice.startIntent(this.readUrl);
        },
        submitLogin() {
            let ok = this.checkForm();
            if (ok !== 'ok') {
                Toast(ok);
                return;
            }
            this.loading = true;
            let options = {
                url: "/web/login",
                data: {
                    userName: this.account,
                    password: crypto.encrypt(this.password),
                }
            };
            request(options).then(res => {
                this.loading = false;
                if (res.data.success) {
                    localStorage.setItem('web_token', res.data.data.token);
                    localStorage.setItem('web_user', JSON.stringify(res.data.data));
                    localStorage.setItem('web_username', res.data.data.userName);
                    localStorage.setItem('web_headerImg', res.data.data.headerImg || '');
                    Toast('登录成功');
                    this.$parent.resetPage();
                } else {
                    Toast.fail(res.data.error.message);
                }
            });
        },
        submitReset() {
            let ok = this.checkResetForm();
            if (ok !== 'ok') {
                Toast(ok);
                return ;
            }
            if (!this.codePattern.test(this.verifyCode)) {
                Toast("验证码为6位数字");
                return ;
            }
            this.loading = true;

            let options = {
                url: "/web/modifyPasswordByPhone",
                data: {
                    newPassword: crypto.encrypt(this.r_password),
                    userName: this.r_account,
                    checkCode: this.verifyCode
                }
            };
            request(options).then((res) => {
                this.loading = false;
                if (res.data.success === true) {
                    Toast('密码重置成功');
                    this.backLoginPage();
                } else {
                    Toast(res.data.error.message);
                    this.finish();
                }
            });
        },
        sendSmsForReset() {
            let ok = this.checkResetForm();
            if (ok !== 'ok') {
                Toast(ok);
                return ;
            }

            this.disabledSend = true;
            this.showSendText = false;
            this.showCountDown = true;
            this.$refs.countDown.start();
            let options = {
                url: "/web/sendSms",
                data: {
                    type: '5',
                    phone: this.r_account
                }
            };
            request(options).then((res) => {
                if (res.data.success === true) {
                    Toast('验证码发送成功');
                } else {
                    Toast(res.data.error.message);
                    this.finish();
                }
            });
        },
        finish() {
            this.disabledSend = false;
            this.showSendText = true;
            this.showCountDown = false;
            this.$refs.countDown.reset();
        },
        checkForm() {
            let ok = 'ok';
            if (!this.account) {
                ok = "请输入"+ this.webName +"账号"
            } else if (!this.password) {
                ok = "请输入密码";
            } else if (!this.accountPattern.test(this.account)) {
                ok = this.webName + "账号为6-12位数字或字母";
            } else if (!this.passwordPattern.test(this.password)) {
                ok = "密码为6-20位数字字母特殊符号";
            }
            return ok;
        },
        submitRegister() {
            let ok = this.checkRegisterForm();
            if (ok !== 'ok') {
                Toast(ok);
                return;
            }
            this.loading = true;
            let options = {
                url: "/web/register",
                data: {
                    channelId: localStorage.getItem("channelId"),
                    userName: this.r_account,
                    password: crypto.encrypt(this.r_password),
                }
            };
            request(options).then(res => {
                this.loading = false;
                if (res.data.success) {
                    Toast('账号注册成功');
                    this.backLoginPage();
                } else {
                    Toast.fail(res.data.error.message);
                }
            });
        },
        checkRegisterForm() {
            if (!this.r_account) {
                return "请输入" + this.webName + "账号";
            }
            if (!this.accountPattern.test(this.r_account)) {
                return this.webName + "账号为6-12位数字字母";
            }
            if (!this.r_password) {
                return "请输入密码";
            }
            if (!this.passwordPattern.test(this.r_password)) {
                return "密码为6-20位数字字母特殊符号";
            }
            if (!this.r_password1) {
                return "请再次输入密码";
            }
            if (this.r_password !== this.r_password1) {
                return "两次密码输入不一致，请重新输入";
            }
            if (!this.isRead) {
                return '账号注册需要同意' + this.webName + '用户协议';
            }
            return "ok";
        },
        checkResetForm() {
            if (!this.r_account) {
                return "请输入" + this.webName + "账号";
            }
            if (!this.accountPattern.test(this.r_account)) {
                return this.webName + "账号为6-12位数字字母";
            }
            if (!this.r_password) {
                return "请输入新密码";
            }
            if (!this.passwordPattern.test(this.r_password)) {
                return "密码为6-20位数字字母特殊符号";
            }
            if (!this.r_password1) {
                return "请再次输入新密码";
            }
            if (this.r_password !== this.r_password1) {
                return "两次新密码输入不一致，请重新输入";
            }
            return "ok";
        },
    }
};
</script>

<style scoped>
.login-form {
    padding: 10px 25px;
    color: #444444;
}

.login-form ::-webkit-input-placeholder {
    color: #888888;
}
.login-form :-moz-placeholder {
    color: #888888;
}
.login-form ::-moz-placeholder {
    color: #888888;
}
.ds-label {
    display: inline-block;
    width: 100%;
    text-align: left;
    margin: 5px 4px 0;
    font-size: 14px;
}
.login-view {
    width: 350px;
    height: 450px;
    background-color: #ffffff;
    border-radius: 12px;
    z-index: 1002;
    position: relative;
    margin: 50px auto 0;
}
.logo-img {
    width: 70px;
    height: 70px;
    margin-top: 20px;
}
.my-read-dialog {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 900px) {
    .login-view {
        width: 80%;
        height: 420px;
        margin-top: 150px;
    }
    .logo-img {
        width: 50px;
        height: 50px;
        margin-top: 20px;
    }
}
</style>