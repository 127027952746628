<template>
    <div class="center" ref="center">
        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                {{pageModel === 'add' ? '我要卖号' : '编辑商品'}}
            </div>
        </div>

        <div class="body">
            <div class="goods-info">
                <div>
                    <span>游戏与角色</span>
                </div>
                <div class="tips-view-in">
                    <span>帐号售出后，该账号下所有游戏的所有角色都将一并售出</span>
                </div>

                <van-popover v-model="showGameSelector" trigger="click"  placement="bottom" style="width: 100%;">
                    <div style="width: 190px;max-height: 140px;overflow-y: auto;font-size: 12px;color: #5e5e5e;padding: 0 15px;box-sizing: border-box;">
                        <div @click="onConfirmGame(item)" v-for="(item,index) in userGameList" style="height: 34px;display: flex;align-items: center;justify-content: flex-start;cursor: pointer;border-bottom: 1px solid #f8f8f8;">
                            <span>{{item.gameName}}</span>
                        </div>
                    </div>
                    <template #reference>
                        <van-field label="选择游戏" readonly label-width="70px" placeholder="请选择游戏" v-model="gameName" clearable></van-field>
                    </template>
                </van-popover>

                <van-popover v-model="showRoleSelector" trigger="click"  placement="bottom" style="width: 100%;">
                    <div style="width: 190px;max-height: 140px;overflow-y: auto;font-size: 12px;color: #5e5e5e;padding: 0 15px;box-sizing: border-box;">
                        <div @click="onConfirmRole(item)" v-for="(item) in roleList" style="height: 34px;display: flex;align-items: center;justify-content: flex-start;cursor: pointer;border-bottom: 1px solid #f8f8f8;">
                            <span>{{item.serverName + ' / ' + item.roleName}}</span>
                        </div>
                    </div>
                    <template #reference>
                        <van-field label="选择区服" @click="pickRole" readonly label-width="70px" placeholder="请选择角色区服" v-model="serverName" clearable></van-field>
                    </template>
                </van-popover>

            </div>

            <div class="goods-info">
                <div>
                    <span>上架信息</span>
                </div>
                <van-field label="上架帐号" placeholder="" readonly  label-width="70px" v-model="username" clearable></van-field>
                <van-field v-if="pageModel === 'add'" label="登录密码" placeholder="用于官方客服登录验号" type="password" label-width="70px" v-model="password" clearable></van-field>

                <van-field label="上架价格" type="digit" maxlength="6" label-width="70px" placeholder="请输入商品价格" v-model="price">
                    <template #button>
                        <span>手续费（5%）：￥{{serviceCharge}}</span>
                    </template>
                </van-field>

                <van-field label="商品标题" label-width="70px" maxlength="30" placeholder="好的标题能提高成交率哦" v-model="title" clearable></van-field>
                <van-field label="商品描述" label-width="70px" maxlength="100" placeholder="描述帐号亮点" v-model="desc" clearable></van-field>
                <van-field label="VIP等级" type="digit" maxlength="2" label-width="70px" placeholder="请输入VIP等级"  v-model="vip" clearable></van-field>
            </div>

            <div class="goods-info" v-show="showLockView">
                <div>
                    <span>游戏安全锁</span>
                </div>
                <div class="tips-view-in">
                    <span>未设置锁密码无须提供，已设置请填写锁密码</span>
                </div>
                <van-field label="道具养成锁" placeholder="如设置请填写" maxlength="20" type="password" label-width="90px" v-model="safePassword1" clearable></van-field>
                <van-field label="货币兑换锁" placeholder="如设置填写" maxlength="20" type="password"  label-width="90px" v-model="safePassword2" clearable></van-field>
                <van-field label="赠送交易锁" placeholder="如设置填写" maxlength="20" type="password" label-width="90px" v-model="safePassword3" clearable></van-field>
            </div>

            <div class="goods-info">
                <div>
                    <span>联系方式</span>
                </div>
                <div class="tips-view-in">
                    <span>用于官方客服更便捷的联系到您</span>
                </div>
                <van-field label="微信" label-width="70px" maxlength="30" placeholder="请输入您的微信号" v-model="wx" clearable></van-field>
                <van-field label="QQ号" type="digit" maxlength="12" label-width="70px" placeholder="请输入您的QQ号"  v-model="qq" clearable></van-field>
            </div>

            <div class="goods-info">
                <div>
                    <span>上传图片</span>
                </div>
                <div class="tips-view-in">
                    <span>请上传1-14张游戏截图，尽可能清楚的展示您的账号信息</span>
                </div>
                <div class="tips-view-in" v-if="isIOS">
                    <van-icon name="warning-o" />
                    <span>苹果手机如果出现选择图片后不展示的问题，请点击右上角的X按钮并重新上传</span>
                </div>
                <div style="padding: 10px 15px;text-align: left">
                    <van-uploader :after-read="afterRead" v-model="imageList" multiple :max-count="14"/>
                </div>
            </div>

            <div class="goods-info">
                <div>
                    <span>身份验证</span>
                </div>
                <div class="tips-view-in">
                    <span>上架帐号必须进行身份验证</span>
                </div>
                <van-field label="绑定手机" label-width="70px" readonly placeholder="" v-model="tel" clearable></van-field>
                <van-field label="验证码" label-width="70px" class="common-input" type="number" maxlength="6" placeholder="请输入验证码" v-model="codeValue" >
                    <template #button>
                        <van-count-down
                                v-show="showCountDown"
                                ref="countDown"
                                :time="60000"
                                :auto-start="false"
                                format="重新发送(ss秒)"
                                style="font-size: 12px;color: #888888"
                                @finish="finish"
                        />
                        <span style="color: #e73d50;font-size: 12px;cursor: pointer;" v-show="!showCountDown" @click="sendCode">获取验证码</span>
                    </template>
                </van-field>
            </div>

            <div v-if="pageModel === 'add'" class="goods-info process-view">
                <div>
                    <span>帐号上架协议</span>
                </div>
                <div class="process-item">
                    <div>1. 卖家提供的密码等敏感信息只有平台客服有权查看，请勿在不信任的设备上上架帐号。</div>
                </div>
                <div class="process-item">
                    <div>2. 卖家帐号上架后，在上架时间内平台客服可能在任意时间上号验号并与买家达成交易，交易完成后平台客服联系卖家转账，若卖家暂时不想出售帐号请及时做下架处理</div>
                </div>

                <div class="process-item">
                    <div>3. 商品上架时间超过30天还未售出的商品将由系统自动下架</div>
                </div>
                <div class="process-item">
                    <div>4. 本平台将会收取5%的手续费（四舍五入，单笔交易最低1元起收）</div>
                </div>
                <div class="process-item">
                    <div>5. 游戏帐号上架之后将禁止该帐号更换绑定手机号码和修改密码</div>
                </div>
                <div class="process-item">
                    <div>6. 账号一经交易完成概不退还，该帐号在平台下的所有游戏角色（不仅仅限于上架帐号的游戏）都将一并被交易</div>
                </div>

                <div class="process-item">
                    <div>7. 上架商品严禁出现个人联系方式、二维码、收号言乱、广告等违禁信息，否则平台将进行拉黑处理，拉黑后无法上架商品</div>
                </div>

                <div class="process-item">
                    <div style="color: #e33e20;font-weight: bold;">8. E兔玩平台官方交易客服不会向卖家索要帐号密码及绑定手机号验证码，请勿向任何人透露该类敏感信息，谨防上当受骗</div>
                </div>


                <div class="process-checkbox" >
                    <van-checkbox v-model="isAgree" icon-size="14px" shape="square" checked-color="#e73d50">
                        <span style="color: #666666;">我已阅读并同意账号上架协议</span>
                    </van-checkbox>
                </div>
            </div>

            <div v-if="pageModel === 'add'" class="bottom-view">
                <van-button type="primary" block color="#ff671c" @click="submit" text="确认上架">
                </van-button>
            </div>

            <div v-else class="bottom-view-edit">
                <van-button type="primary" color="#aaaaaa" @click="offGoods" style="width: 45%;font-weight: bold;" text="下架">
                </van-button>
                <van-button type="primary" color="#ff671c" @click="submitEdit" style="width: 45%;font-weight: bold;" text="修改商品">
                </van-button>
            </div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Dialog, Uploader} from 'vant';
    import {Toast} from 'vant';
    import {webTokenRequest} from '@/api/request';
    import myloading from '@/components/myloading';
    import crypto from '@/utils/crypto.js';
    Vue.use(Uploader);
    
    export default {
        name: "GoodsAdd",
        components: {
            myloading
        },
        data() {
            return {
                username: '',
                password: '',
                serverName: '',
                price: '',
                title: '',
                vip: '',
                desc: '',
                tel: '',
                codeValue: '',
                wx: '',
                qq: '',
                saleToken: '',
                safePassword1: '',
                safePassword2: '',
                safePassword3: '',

                showLoading: false,
                showCountDown: false,

                imageList: [],
                passwordRegexp: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,20}$/,
                codeReg: /\d{6}/,
                serviceCharge: 0,
                selectedCard: '1',
                showGameSelector: false,
                gameList: [],
                userGameList: [],
                gameNameList: [],
                gameId: 0,
                gameName: '',
                isAgree: false,
                saleDays: 30,
                isIOS: false,
                roleList: [],
                roleNameList: [],
                showRoleSelector: false,
                showLockView: false,
                canvasId: "myCanvas",
                pageModel: 'add',
                goodsId: '',
            }
        },
        watch: {
            price(newVal, oldVal) {
                this.serviceCharge = Math.round(newVal * 0.05);
            },
            gameId(n, o) {
                this.showLockView = true;
                this.getRoleList();
            }
        },
        mounted() {
            let ua = navigator.userAgent.toLowerCase();
            if (/iphone|ipad|ipod/.test(ua)) {
                this.isIOS = true;
            }
            //判断是否是编辑页面
            if (this.$route.query.goodsId) {
                this.goodsId = this.$route.query.goodsId;
                this.initPageForEdit();
                this.pageModel = 'edit';
            }
            else {
                this.getUserInfoTrade();
                this.pageModel = 'add';
            }

        },
        methods: {
            initPageForEdit() {
                let options = {
                    url:"/trade/getDetailForUpdate?goodsId=" + this.goodsId,
                    method: 'get'
                };

                webTokenRequest(options).then(res => {
                    if (res.data.success) {
                        let data = res.data.data;
                        this.username = data.userName;
                        this.serverName = data.serverName;
                        this.price = data.price;
                        this.title = data.title;
                        this.vip = data.vip;
                        this.desc = data.desc;
                        this.wx = data.wx;
                        this.qq = data.qq;
                        this.offTime = data.offTime;
                        this.gameName = data.gameName;
                        this.gameId = parseInt(data.gameId);
                        this.goodsType = parseInt(data.goodsType);
                        this.tel = data.phone;
                        this.safePassword1 = this.decrypt(data.safePassword1);
                        this.safePassword2 = this.decrypt(data.safePassword2);
                        this.safePassword3 = this.decrypt(data.safePassword3);
                        this.imageList = [];
                        for (let i = 0; i < data.imgList.length; i++) {
                            let item = {
                                url: data.imgList[i]
                            }
                            this.imageList.push(item);
                        }
                        console.log(this.serverName)
                        //this.getRoleList();

                        let n = this.gameId;
                        this.showLockView = (n === 1 || n === 2 || n === 48);
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            onConfirmGame(item) {
                this.gameId = item.gameId;
                this.gameName = item.gameName;
                this.showGameSelector = false;
            },
            onConfirmRole(item) {
                this.serverName = item.serverName;
                this.showRoleSelector = false;
            },
            back() {
                this.$router.back();
            },
            pickRole()  {
                if (this.pageModel !== 'add') {
                    return;
                }
                if (this.roleList.length === 0) {
                    Toast('请先选择游戏');
                }
            },
            getUserInfoTrade() {
                let options = {
                    url: "/trade/getUserInfoTrade",
                    method: 'get'
                }

                webTokenRequest(options).then(res => {
                    if (res.data.success) {
                        this.userGameList = res.data.data.gameForTradeList;
                        this.username = res.data.data.username;
                        this.tel = res.data.data.phone;
                        this.userGameList.forEach(e => {
                            this.gameNameList.push(e.gameName);
                        })
                    }
                    else {
                        this.showDialogThenReturn(res.data.error.message);
                    }
                });
            },
            showDialogThenReturn (msg) {
                Dialog.alert({
                    title: '提示',
                    message: msg,
                    messageAlign: 'center'
                }).then(() => {
                    this.back();
                });
            },
            getRoleList() {
                if (this.pageModel !== 'add') {
                    return;
                }
                this.roleNameList = [];
                this.roleList = [];
                this.serverName = '';
                let options = {
                    url: "/trade/getRoleList?gameId=" + this.gameId,
                    method: 'get',
                }

                webTokenRequest(options).then(res => {
                    if (res.data.success) {
                        this.roleList = res.data.data;
                        this.roleList.forEach((e) => {
                            this.roleNameList.push(e.serverName + " " + e.roleName);
                        })
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });

            },
            submit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }

                this.showLoading = true;
                let base64List = [];
                for (let i = 0; i < this.imageList.length; i++) {
                    base64List.push(this.imageList[i].content)
                }
                let passwordParam = crypto.encrypt(this.password);

                let options = {
                    url:"/trade/addGoods",
                    data: {
                        checkCode: this.codeValue,
                        desc: this.desc,
                        goodsType: this.goodsType,
                        imgList: base64List,
                        username: this.username,
                        password: passwordParam,
                        price: this.price,
                        qq: this.qq,
                        wx: this.wx,
                        serverName: this.serverName,
                        title: this.title,
                        vip: this.vip,
                        gameId: this.gameId,
                        gameName: this.gameName,
                        saleDays: this.saleDays,
                        safePassword1: this.encrypt(this.safePassword1),
                        safePassword2: this.encrypt(this.safePassword2),
                        safePassword3: this.encrypt(this.safePassword3),
                    }
                };
                webTokenRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('帐号上架成功');
                        setTimeout(() => {
                            this.back();
                        }, 2000);
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            offGoods() {
                Dialog.confirm({
                    title: '提示',
                    message: '确认下架商品吗？',
                }).then(() => {
                    this.showLoading = true;
                    let options = {
                        url:"/trade/moveGoods",
                        data: {
                            goodsId: this.goodsId
                        }
                    };
                    webTokenRequest(options).then(res => {
                        this.showLoading = false;
                        if (res.data.success) {
                            Toast('下架成功');
                            setTimeout(() => {
                                this.back();
                            }, 2000);
                        }
                        else {
                            Toast(res.data.error.message);
                        }
                    });


                }).catch(() => {});
            },
            submitEdit () {
                let ok = this.checkFormEdit();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }
                this.showLoading = true;

                let resultImageList = [];
                for (let i = 0; i < this.imageList.length; i++) {
                    resultImageList.push(this.imageList[i].url || this.imageList[i].content)
                }

                let options = {
                    url:"/trade/editGoods",
                    data: {
                        checkCode: this.codeValue,
                        desc: this.desc,
                        goodsType: this.goodsType,
                        imgList: resultImageList,
                        price: this.price,
                        qq: this.qq,
                        wx: this.wx,
                        title: this.title,
                        vip: this.vip,
                        goodsId: this.goodsId,
                        safePassword1: this.encrypt(this.safePassword1),
                        safePassword2: this.encrypt(this.safePassword2),
                        safePassword3: this.encrypt(this.safePassword3),
                    }
                };
                webTokenRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('商品信息修改成功');
                        setTimeout(() => {
                            this.back();
                        }, 2000);
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            checkFormEdit() {
                if (!this.price) {
                    return '请输入商品价格';
                }
                if (!this.title) {
                    return '请输入商品标题';
                }
                if (!this.vip) {
                    return '请输入VIP等级';
                }
                if (!this.desc) {
                    return '请输入商品描述';
                }
                if (parseInt(this.price) < 20 ) {
                    return '帐号出售价格不能低于20元';
                }
                if (this.imageList.length === 0) {
                    return '请上传1-14张商品图片';
                }
                if (!this.tel) {
                    return '未绑定手机无法上架商品';
                }
                if (!this.codeValue) {
                    return '请输入短信验证码';
                }
                if (!this.codeReg.test(this.codeValue)) {
                    return '验证码为6位数字';
                }

                return 'ok';
            },
            encrypt(p) {
                if (!p) {
                    return '';
                }
                else {
                    return crypto.encrypt(p);
                }
            },
            decrypt(p) {//解码
                if (!p) {
                    return '';
                }
                else {
                    return crypto.decrypt(p)
                }
            },
            checkForm() {
                if (!this.tel) {
                    return '未绑定手机无法上架商品';
                }
                if (this.gameId === 0) {
                    return '请选择游戏';
                }
                if (!this.serverName) {
                    return '请选择游戏区服';
                }
                if (!this.passwordRegexp.test(this.password)) {
                    return '登录密码格式错误';
                }
                if (!this.price) {
                    return '请输入商品价格';
                }
                if (!this.title) {
                    return '请输入商品标题';
                }
                if (!this.vip) {
                    return '请输入VIP等级';
                }
                if (!this.desc) {
                    return '请输入商品描述';
                }
                if (parseInt(this.price) < 20 ) {
                    return '帐号出售价格不能低于20元';
                }
                if (this.imageList.length === 0) {
                    return '请上传1-14张商品图片';
                }
                if (!this.codeValue) {
                    return '请输入短信验证码';
                }
                if (!this.codeReg.test(this.codeValue)) {
                    return '验证码为6位数字';
                }
                if (!this.isAgree) {
                    return '请阅读并同意帐号上架协议';
                }
                return 'ok';
            },
            sendCode() {
                this.showLoading = true;
                if (!this.tel) {
                    Toast('未绑定手机无法上架商品');
                    return ;
                }
                let options = {
                    url:"/trade/sendCheckMsg",
                    data: {}
                };
                webTokenRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.showCountDown = true;
                        this.$refs.countDown.start();
                        Toast('验证码发送成功');
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            finish() {
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            afterRead(file,info) {
                if (file instanceof Array) {
                    let index = info.index;
                    for (let i = 0; i < file.length; i++) {
                        this.imgPreview(file[i].file, index++);
                    }
                }
                else {
                    this.imgPreview(file.file, info.index);
                }
            },
            imgPreview(file, index) {
                let _this = this;
                // file.status = 'uploading';
                // file.message = '上传中...';
                const trueFile = file;
                const size = trueFile.size / 1024;
                console.log(`压缩前 ===>${size}k`);
                //图片并且大于2M开始压缩
                if (/^image/.test(trueFile.type) && size >= 2000) {
                    // 创建一个reader
                    let reader = new FileReader();
                    // 将图片2将转成 base64 格式
                    reader.readAsDataURL(trueFile);
                    // 读取成功后的回调
                    reader.onloadend = function() {
                        let result = this.result;
                        let img = new Image();
                        img.src = result;
                        img.onload = function() {
                            // 压缩
                            let data = _this.compress(img, trueFile.name, trueFile.type);
                            console.log(`压缩后 ===>${data.fileData.size/1024}k`);
                            file.content = data.base64Data;
                            file.file = data.fileData;
                            let old = _this.imageList[index];
                            old.content = data.base64Data;
                            _this.imageList[index] = old;
                        }
                    }
                }
            },
            // 压缩图片
            compress(img, name, type) {
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                //瓦片canvas
                let tCanvas = document.createElement('canvas');
                let tctx = tCanvas.getContext('2d');
                // let initSize = img.src.length;
                let width = img.width;
                let height = img.height;
                //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
                let ratio;
                if ((ratio = (width * height) / 4000000) > 1) {
                    // console.log("大于400万像素");
                    ratio = Math.sqrt(ratio);
                    width /= ratio;
                    height /= ratio;
                } else {
                    ratio = 1;
                }
                canvas.width = width;
                canvas.height = height;
                //    铺底色
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                //如果图片像素大于100万则使用瓦片绘制
                let count;
                if ((count = (width * height) / 1000000) > 1) {
                    //console.log("超过100W像素");
                    count = ~~(Math.sqrt(count) + 1) //计算要分成多少块瓦片
                    //计算每块瓦片的宽和高
                    let nw = ~~(width / count);
                    let nh = ~~(height / count);
                    tCanvas.width = nw;
                    tCanvas.height = nh;
                    for (let i = 0; i < count; i++) {
                        for (let j = 0; j < count; j++) {
                            tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio,
                                0, 0, nw, nh);
                            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
                        }
                    }
                } else {
                    ctx.drawImage(img, 0, 0, width, height);
                }
                //进行压缩
                let ndata = canvas.toDataURL('image/jpeg', 0.8);
                tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
                return {
                    base64Data: ndata,
                    fileData: this.dataURLtoFile(ndata, name, type)
                }
            },
            //将base64转换为文件
            dataURLtoFile(dataurl, name, type) {
                name = name ? name : '图片';
                type = type ? type : 'jpg';
                var arr = dataurl.split(','),
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], name, {
                    type: type
                })
            },
            loadImageToCanvas(url){
                const canvas = document.getElementById(this.canvasId);
                const context = canvas.getContext("2d");
                const image = new Image();
                image.crossOrigin = "anonymous";
                image.onload = () => {
                    const {width,height} = image;
                    canvas.width = width;
                    canvas.height = height;
                    context.drawImage(image, 0, 0, width,height);
                };
                image.src = url;
            },
        }
    }
</script>

<style scoped>
    .m-title {
        display: flex;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #f8f7f5;
        box-sizing: border-box;
    }
    @font-face{
        font-family:myFont;
        src: url(../../assets/myFont.TTF);
    }
    .process-view {
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        padding: 5px 0;
        margin-bottom: 10px;
    }
    .process-item {
        display: flex;
        font-size: 13px;
        padding: 6px 15px;
        align-items: flex-start;
    }

    .process-item > div {
        flex: 1;
        text-align: left;
        margin-left: 5px;
    }
    .process-checkbox {
        display: flex;
        font-size: 13px;
        height: 40px;
        padding: 0 15px;
        align-items: center;
        color: #5A6EFC;
        margin-top: 5px;
        border-top: 1px solid #EEEEEE;
    }

    .checkbox-unselect {
        width: 30px;

    }

    .center {
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow-y: auto;
        background-color: #f8f7f5;
    }
    .title{
        height: 50px;
        background-color: #f14f4f;
        color: #FFFFFF;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .back-arrow{
        position: absolute;
        left: 10px;
    }
    .body{
        overflow-y: auto;
        height: calc(100vh - 110px);
        padding: 110px 15px 0;
    }
    .type-select{
        display: flex;
        align-items: center;
        padding: 0 15px 10px;
        font-size: 12px;
        flex-wrap: wrap;
    }
    .type-select > div {
        border: 1px solid #AAAAAA;
        color: #AAAAAA;
        height: 30px;
        padding: 0 10px;
        line-height: 30px;
        text-align: center;
        border-radius: 2px;
        margin-top: 15px;
        margin-right: 10px;
    }
    .active{
        border: 2px solid #ff8400 !important;
        color: #ff8400 !important;
        font-weight: bold;
    }
    .game-active {
        outline: 2px solid #ff3c00 !important;
        border-radius: 3px;
        background-color: rgba(255, 60, 0, 0.05);
    }
    .tips-view{
        text-align: left;
        font-size: 12px;
        padding: 10px;
    }
    .tips-view-in{
        text-align: left;
        font-size: 12px;
        color: #888888;
        padding: 5px 15px;
    }
    .goods-info{
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 5px 0;
        margin-bottom: 10px;
    }
    .goods-info > div:first-child {
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        color: #ff671c;
        padding: 5px 15px;
    }
    .bottom-view{
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .van-cell{
        font-size: 13px;
    }
    .bottom-view-edit {
        margin-top: 20px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
    }
</style>