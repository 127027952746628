<template>
    <div class="m-view m-main" style="height: 250px;background-color: #3b4154;width: 100%;display: flex;flex-direction: column;align-items: center;color: #FFFFFF">
        <div style="display: flex;align-items: center;margin-top: 30px;" class="no-select" v-if="!!config.privacy && !!config.agreement">
           <!-- <span>关于我们</span>
            <div class="column-line"></div>-->
            <span @click="openPolicyPage">隐私政策</span>
            <div class="column-line"></div>
            <span @click="openAgreementPage">用户协议</span>
            <!-- <div class="column-line"></div>
            <span>平台公告</span>-->
        </div>

        <div class="m-view m-font" style="color: #aaaaaa;font-size: 14px;margin-top: 20px;display: flex;flex-direction: column">
            <span>{{ config.copyright }}</span>
            <span class="info-text">{{ config.companyName }}</span>
            <span class="info-text">联系地址：{{ config.address }}</span>
            <span class="info-text"><a style="color: #aaaaaa;cursor:pointer;" href="https://beian.miit.gov.cn"> {{ config.icp }} </a></span>
            <!--<div style="display: flex;justify-content: center;margin-top: 15px;">
                <img src="../assets/gonganbu.png" style="width: 20px;">
                <a style="color: #aaaaaa;cursor:pointer;margin-left: 5px;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=">X公网安备 XXXXXXXXXXXXX号</a>
            </div>-->
            <div  class="info-text" style="display: flex;flex-wrap: wrap">
                <span>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。</span>
                <span>适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</span>
            </div>

        </div>



    </div>
</template>

<script>
    import {request} from "@/api/request";
    import EventBus from "@/api/EventBus";
    import webConfig from "@/api/config";

    export default {
        name: "HomeFooter",
        data() {
            return {
                config: {
                  icp: '',
                  gongan: '',
                  copyright: '',
                  address: '',
                  companyname: '',
                  privacy: '',
                  agreement: ''
                },
            }
        },
        mounted() {
          this.initPage();
        },
        methods: {
            initPage() {
                this.config = webConfig;
            },
            openPolicyPage() {
                window.open(this.config.privacy, '');
            },
            openAgreementPage() {
                window.open(this.config.agreement, '');
            },
          }
    }
</script>

<style scoped>
    .column-line {
        display: inline-block;
        width: 1px;
        height: 15px;
        background-color: #ffffff;
        margin-left: 10px;
        margin-right: 10px;
    }
    .no-select {
        user-select: none;
        cursor: pointer;
    }
    .info-text {
        margin-top: 15px;
    }
    @media screen and (max-width: 900px) {
        .m-view {
            align-items: flex-start !important;
            text-align: left;
        }
        .info-text {
            margin-top: 8px;
        }
        .m-font {
            font-size: 12px !important;
        }
        .m-main {
            padding: 0 15px;
            box-sizing: border-box;
        }
    }

</style>