<template>
    <div class="main-container">
        <van-form @submit="onSubmit" ref="submitForm">
            <div class="dialog-title">
                <div style="margin-left: 5px;">绑定手机</div>
            </div>

            <div style="margin: 10px 20px;height: 250px;overflow-y: auto;">

                <div style="color: #aaaaaa;font-size: 12px;text-align: left">
                    为了账号安全，请及时绑定手机号码。
                </div>

                <van-field label-width="60px" label="手机号码" label-class="inline-label" placeholder="请输入手机号"
                           style="margin-top: 10px;color: #ffffff;"  v-model="tel" clearable/>

                <van-field label-width="60px" label="验证码" label-class="inline-label" style="margin-top: 20px;"
                           v-model="verifyCode" type="number"
                           placeholder="请输入验证码">
                    <template #button>
                        <van-count-down
                                v-show="showCountDown"
                                ref="countDown"
                                :time="60000"
                                :auto-start="false"
                                format="重新发送(ss秒)"
                                style="font-size: 12px;color: #888888"
                                @finish="finish"
                        />
                        <span style="color: #e73d50;font-size: 12px;" v-show="!showCountDown" @click="sendSmsForBind">发送验证码</span>
                    </template>
                </van-field>

                <div class="box-button">
                    <van-button type="info" block native-type="submit" size="small" :loading="loading" round style="width: 160px;"
                                color="#e73d50">
                        立即绑定
                    </van-button>
                </div>


                <div v-if="!backShow" style="font-size: 12px;margin-top: 15px;display: flex;justify-content: center;padding: 0 3px;">
                    <div @click="miss" style="color: #e73d50">跳过绑定</div>
                </div>
            </div>
        </van-form>

    </div>
</template>

<script>

    import {sendSms, bindPhone} from '@/api/request'
    import verifyCodeIcon from "@/assets/verifyCode.png";
    import phoneIcon from "@/assets/phone.png";
    import backIcon from "@/assets/back.png";

    import {Dialog, Toast} from 'vant'

    export default {
        name: 'changePassword',
        data() {

            return {
                phoneIcon,
                verifyCodeIcon,
                backIcon,
                tel: '',
                verifyCode: '',
                missShow: false,
                backShow: true,
                telPattern: /^1\d{10}$/,
                codePattern: /^\d{6}$/,
                showCountDown: false,
                showSendText: true,
                disabledSend: false,
                loading: false,
            };
        },
        methods: {
            getParent() {
                let from = localStorage.getItem("from");
                if (from === 'android_sdk') {
                    return  this.$parent;
                }
                else {
                    return this.$parent.$parent;
                }
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.loading = true;
                let params = {
                    phone: this.tel,
                    checkCode: this.verifyCode,
                };
                bindPhone(params).then((res) => {
                    this.loading = false;
                    if (res.data.success === true) {
                        Toast.success('手机号码绑定成功');
                        let user = JSON.parse(localStorage.getItem('user'));
                        user.phone = this.tel;
                        localStorage.setItem('user', JSON.stringify(user));
                        if (!this.backShow) {
                            this.miss();
                        } else {
                            this.back();
                        }
                        this.back();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            sendSmsForBind() {
                let ok = this.checkTel();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.disabledSend = true;
                this.showSendText = false;
                this.showCountDown = true;
                this.$refs.countDown.start();
                let params = {
                    phone: this.tel,
                    type: '2',
                };
                sendSms(params).then((res) => {
                    if (res.data.success === true) {
                        Toast('验证码发送成功');
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            checkTel() {
                if (!this.tel) {
                    return "请输入手机号码";
                }
                if (!this.telPattern.test(this.tel)) {
                    return "请输入正确的手机号码";
                }
                return 'ok';
            },
            checkForm() {
                if (!this.tel) {
                    return "请输入手机号码";
                }
                if (!this.telPattern.test(this.tel)) {
                    return "请输入正确的手机号码";
                }
                if (!this.verifyCode) {
                    return "请输入验证码";
                }
                if (!this.codePattern.test(this.verifyCode)) {
                    return "验证码为6位数字";
                }
                return 'ok';
            },
            finish() {
                this.disabledSend = false;
                this.showSendText = true;
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            miss() {
                this.getParent().missBindTel();
            }
        }
    }
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }

    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }

    /deep/.inline-label {
        color: #aaaaaa !important;
    }

    .van-cell {
        padding: 8px 14px;
    }

    .van-field {
        background-color: #2f2f2f;
        color: #ffffff;
    }
    /deep/.van-field__control {
        color: #ffffff;
    }
    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
</style>
