<template>
    <div class="main-view">
        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                我的交易
            </div>
        </div>

        <div class="trade-view">
            <div class="trade-list-view">
                <div style="font-weight: bold;font-size: 16px;color: #ff671c;padding: 5px 0;margin-bottom: 10px;text-align: left;">
                    我上架的商品
                </div>
                <div v-if="!!myGoodsInfo" class="goods-item" @click="goDetailPage(myGoodsInfo.goodsId)">
                    <van-image :src="myGoodsInfo.firstImg" style="width: 130px;height: 130px;border-radius: 3px;" fit="cover"/>
                    <div class="goods-content">
                        <div class="goods-title">
                            <span class="info-title">{{myGoodsInfo.title}}</span>
                        </div>
                        <div>
                            <span>{{myGoodsInfo.gameName}}</span>
                        </div>
                        <div style="color: #888888">
                            <span>{{myGoodsInfo.serverName}}</span>
                        </div>

                        <div style="color: #f35909;font-size: 10px;">
                            <span>￥</span>
                            <span style="font-size: 16px;font-weight: bold;">{{myGoodsInfo.price}}</span>
                            <span v-if="!!myGoodsInfo.vip" style="margin-left: 20px;color: #e4730e;border: 1px solid rgba(228,115,14,0.15);background-color: rgba(228,115,14,0.08); border-radius: 1px; padding: 1px 4px;font-size: 14px;">
                                    <span style="font-size: 10px;">VIP</span>
                                    <span style="font-weight: bold;margin-left: 2px;">{{myGoodsInfo.vip}}</span>
                                </span>
                        </div>
                    </div>
                </div>
                <div v-else style="font-size: 13px;color: #888888;margin: 20px 0;">
                    还没有上架帐号
                </div>
            </div>

            <div class="trade-list-view" style="margin-top: 10px;">
                <div style="font-weight: bold;font-size: 16px;color: #ff671c;padding: 5px 0;margin-bottom: 10px;text-align: left;">
                    我的订单
                </div>
                <div v-if="myOrderList.length > 0" >
                    <div v-for="item in myOrderList" class="goods-item" @click="goOrderPage(item.orderId)">
                        <van-image :src="item.firstImg" style="width: 100px;height: 100px;border-radius: 3px;" fit="cover"/>
                        <div class="goods-content" style="padding: 5px 10px;">
                            <div style="">
                                <span style="margin-right: 15px;color: #888888">No. <span style="color: #333333;font-weight: bold;">{{item.orderId}}</span></span>
                                <span v-if="item.orderStatus === '1'">待支付</span>
                                <span v-if="item.orderStatus === '2'">已支付</span>
                                <span v-if="item.orderStatus === '3'">已完成</span>
                                <span v-if="item.orderStatus === '4'">已撤销</span>
                            </div>
                            <div class="goods-title">
                                <span  style="font-size: 14px;">{{item.title}}</span>
                            </div>
                            <div>
                                <span>{{item.gameName}}</span>
                            </div>
                            <div style="color: #888888" v-if="false">
                                <span>{{item.serverName}}</span>
                            </div>

                            <div style="color: #f35909;font-size: 10px;">
                                <span>￥</span>
                                <span style="font-size: 14px;font-weight: bold;">{{item.price}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else style="font-size: 13px;color: #888888;margin: 20px 0;">
                    还没有订单数据
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import {request, webTokenRequest} from '@/api/request'
    import {Toast} from 'vant'

    import gzhCodeView from "@/components/home/GzhCodeView";
    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import webConfig from "@/api/config";

    export default {
        name: 'my-trade',
        components: {
            homeNav,
            gzhCodeView,
            homeFooter,
        },
        data() {
            return {
                webConfig: {},
                myGoodsInfo: null,
                myOrderList: [],
            }
        },
        mounted() {
            this.webConfig = webConfig;
            this.initData();
        },
        methods: {
            back() {
                this.$router.back();
            },
            initData() {
                this.showLoading = true;
                let options = {
                    url:"/trade/getMyTradeData",
                    method: 'get',
                };
                webTokenRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        if (res.data.data.myGoodsInfo) {
                            this.myGoodsInfo = res.data.data.myGoodsInfo;
                        }
                        this.myOrderList = res.data.data.myOrderList;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            goDetailPage(id) {
                this.$router.push("/trade/goodsAdd?goodsId=" + id)
            },
            goOrderPage(id) {
                this.$router.push("/trade/orderDetail?id=" + id)
            },
        }
    }
</script>
<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f8f7f5;
    }
    .m-title {
        display: flex;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #f8f7f5;
        box-sizing: border-box;
    }
    .trade-view {
        width: 100%;
        height: calc(100vh - 110px);
        margin-top: 110px;
    }
    .trade-list-view {
        padding: 10px 15px;
        background-color: #FFFFFF;
        border-radius: 5px;
    }
    .goods-item{
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        font-size: 14px;
    }
    /deep/.goods-item img{
        border-radius: 5px;
    }
    .goods-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px 0 10px 10px;
    }

    .goods-title {
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-align: left;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    }
</style>

