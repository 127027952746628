import serverArr from "@/api/servers";

const webConfig =  {
    //应用名称
    appName: "E兔玩",
    //应用名称
    gameAppName: "E兔玩游戏",
    //应用图标
    logoUrl: "https://etuw.hn12oss.ctyunxs.cn/webresource/etlogo.png",
    //圆形logo
    logoRoundUrl: "https://etuw.hn12oss.ctyunxs.cn/webresource/logo.png",
    //微信公众号
    wechatUrl: "https://etuw.hn12oss.ctyunxs.cn/webresource/wxgzh.png",
    //应用二维码
    appqrUrl: "",
    //icp备案
    icp: "蒙ICP备2021003855号-1",
    //公安备案
    publicSecurity: "",
    //版权所有
    copyright: "copyright © 2024 etuw.cn/ All Rights Resered.",
    //公司地址
    address: "内蒙古自治区呼和浩特市和林格尔县云计算大数据创客中心三号楼207室、210室",
    //公司名称
    companyName: "内蒙古沃扬网络科技有限公司",
    //公司名称
    copyrightCompany: "沃扬网络 版权所有",
    //用户协议
    agreement :  "https://game.etuw.cn/static/rule/agreement.html",
    //隐私政策
    privacy :  "https://game.etuw.cn/static/rule/policy.html",
    //QQ客服
    qqkfList: [
        {"info": "2788188888", "worktime": "09:00-22:00"}
    ],
    wxkfList: [
        {"info": "https://work.weixin.qq.com/kfid/kfc298fd74660766c12", "worktime": "09:00-22:00"}
    ],
    telkfList: [
        {"info": "4006183736", "worktime": "09:00-22:00"}
    ],
    //平台币名称
    coinName: "金魂币",
    //游戏加速器地址
    speedUrl: "https://game.etuw.cn/test/a.html?genCode=true",
    //sdkweb地址
    sdkUrl: "https://game.etuw.cn/f/#/sdk/main",
    //默认搜索游戏名
    defaultSearchName: "灵眸极速服",
    hotSearchName: ["斗罗大陆", "灵眸", "灵眸极速服"],
    appDownloadUrl: 'https://etuw.hn12oss.ctyunxs.cn/mrvfffoc/1/E兔玩.apk',
}

export default webConfig